import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import Home from './Pages/Home';
import Error404 from './Pages/Error/404';
import PreRegister from './DemoRegister/PreRegister';
import PreComplete from './DemoRegister/PreComplete';
import FindRegister from './DemoRegister/FindRegister';
import SignAgreement from './DemoRegister/SignAgreement';
import Confirmed from './DemoRegister/Confirmed';
import NewRegister from './DemoRegister/NewRegister';
import newSignAgreement from './DemoRegister/newSignAgreement';
import showroomDemoRegister from './Showroom/DemoRegister';
import DemoSignAgreement from './Showroom/DemoSignAgreement';
import DemoRegisterConfirmed from './Showroom/DemoRegisterConfirmed';

import { ServicePromoForm, SpinPage } from './Promos/PromoServiceWheel';

import Finance from './finance';
import Form from './Form'

const Views = () => {
  return(
    <>
      {/* <Navbar /> */}
      <Switch>
        <Route exact path="/Home" component={Home} />
        <Route exact path="/" >
          <Redirect to="/Home" />
        </Route>
        <Route exact path="/preregister/reg" component={PreRegister} />
        <Route exact path="/preregister/complete" component={PreComplete} /> 
        <Route exact path="/postregister/find" component={FindRegister} /> 
        <Route exact path="/postregister/sign" component={SignAgreement} />
        <Route exact path="/postregister/sign2" component={newSignAgreement} />
        <Route exact path="/postregister/success" component={Confirmed} />
        <Route exact path="/preregister/newreg" component={NewRegister} />
        <Route exact path="/showroom/demoregister" component={showroomDemoRegister} />
        <Route exact path="/showroom/demosignagreement" component={DemoSignAgreement} />
        <Route exact path="/showroom/democomfirmed" component={DemoRegisterConfirmed} />
        <Route exact path="/wheel-spinner" component={SpinPage} />
        <Route exact path="/finance/calculator" component={Finance} />
        <Route exact path="/services" component={() => <>SERVICES</>} />
        <Route path="/form" component={Form} />
        <Route path="*" component={Error404} />
      </Switch>
    </>
  )
}

export default Views