import { message, Form, Input, InputNumber, Checkbox, Button, Spin, Radio, Select, DatePicker, Image, Typography, Empty, Skeleton, Result, Upload } from 'antd'
import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom"
import moment from 'moment'
import 'moment/locale/th';
import locale from 'antd/lib/locale/th_TH'
import { placeholderImage } from './data-image'
import Markdown from 'markdown-to-jsx'
import { Helmet } from 'react-helmet'
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select
const { RangePicker } = DatePicker

export default function FormBody({history, ...props}){

  const [ formState, setFormState ] = useState(undefined) // undefined=initial, null=loading, true=ready, false=error
  const [ formData, setFormData ] = useState()

  const [ submitStatus, setSubmitStatus ] = useState() // undefined=initial, null=loading, true=done, false=error

  const { formId } = useParams()
  const [ form ] = Form.useForm()

  useEffect(() => {
    async function fetchFormBody(){
      try {
        setFormState(null)
        const response = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/forms?slug=${formId}`)
        if(!response.ok){
          throw {status: "error", reason: "response not ok", data: response}
        }
        const arrJson = await response.json()
        if(arrJson.length === 0){
          throw {status: "error", reason: "form not found"}
        }
        if(arrJson.length >= 2){
          throw {status: "error", reason: "form found more than 1"}
        }
        setFormData(arrJson[0])
        setFormState(true)
      } catch (error) {
        console.error(error)
        message.error(`Catched Error! please see console.`)
        setFormState(false)
      }
    }
    fetchFormBody()
  },[])

  return (
    <>
    {
      formState === undefined || formState === null ?
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Loading...</title>
        </Helmet>
        <Skeleton active /> 
      </> 
      :
      formState === false ?
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Error - Real Motosports Form`}</title>
        </Helmet>
        <Result
          status="404"
          title="Form not found"
          subTitle="Form endpoint not found or error. Please check url."
          extra={
            <Button type="primary" onClick={() => history.goBack()} >Back</Button>
          }
        /> 
      </>
      :
      formState === true ? 
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${formData.name} - Real Motosports Form`}</title>
        </Helmet>
        <Form 
          form={form} 
          name={formData.internal_name} 
          id={formData.internal_name} 
          onFinish={async form_values => {
            setSubmitStatus(null)
            try {
              let new_form_values = []
              for (const [key, value] of Object.entries(form_values)){
                const matchKey = formData.fields.filter(item => {
                  if(item.name === key){
                    return item
                  }
                })
                const matchComponent = matchKey[0]["form_submission_component"]
                if(!matchComponent){
                  throw new Error("Missing Matched form_submission_component 😥")
                }
                new_form_values = [
                  ...new_form_values, 
                  {
                    __component: matchComponent,
                    name: key,
                    value: value
                  }
                ]
              }
              let expand_form_values = new_form_values.map(item => {
                if(Array.isArray(item.value)){
                  return {
                    ...item,
                    values: item.value.map((_item)=>({value: _item}))
                  }
                }
                return {...item}
              })
              const submit_response = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/forms/submit/${formData.id ? formData.id : () => {throw new Error("missing id in formData")}}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  fields: expand_form_values
                })
              })
              if(!submit_response.ok){
                throw new Error(await submit_response.text())
              }
              const response_json = await submit_response.json()
              const { nextAction } = response_json
              if(!nextAction){
                throw new Error(`Missing nextAction. So what's next ?`)
              }
              switch(nextAction){ // redirect
                case `redirect`:
                  const { url, params } = response_json
                  if(!url){
                    throw new Error(`Missing url when nextAction is redirect. where to ?`)
                  }
                  const queryString = [...Object.entries(params)].map(([key, value], index) => index === 0 ? `?${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("")
                  history.replace({
                    pathname: `/form${url}`,
                    search: queryString
                  })
                  setSubmitStatus(true)
                return;
                default:
                  throw new Error(`The nextAction contain unknown operator. I don't know what to do next.`)
              }
              setSubmitStatus(true)
            } catch (error) {
              message.error(error.message)
              console.error(error)
              setSubmitStatus(false)
            }
          }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
        >
          <div style={{marginTop: "30px"}}>
            {formData.name ? <Form.Item label=" "><Typography.Title>{formData.name}</Typography.Title></Form.Item> : <React.Fragment></React.Fragment>}
          </div>
          {
            !formData.fields ? <Spin /> :
            formData.fields.length === 0 ? <Empty description={<span>No form fields. Please create.</span>}><Button type="primary" onClick={() => history.goBack()}>Back</Button></Empty> :
            <>
              { // form field render
                formData.fields.map((field, field_index) => {
                  switch(field.__component){
                    case "form-data.text" :             //////////////////////////////////////////////////////////////////////////////// TEXT
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[{type: "string", min: field.min_length, max: field.max_length},{required: field.required}]}
                        >
                          <Input />
                        </Form.Item>
                      )
                    case "form-data.number" :           //////////////////////////////////////////////////////////////////////////////// NUMBER
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[{type: "number", min: field.min_value, max: field.max_value},{required: field.required}]}
                        >
                          <InputNumber style={{width: "100%"}} />
                        </Form.Item>
                      )
                    case "form-data.text-multiline" :   //////////////////////////////////////////////////////////////////////////////// MULTILINE
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[{type: "string"},{required: field.required}]}
                        >
                          <Input.TextArea />
                        </Form.Item>
                      )
                    case "form-data.checkbox-single" :  //////////////////////////////////////////////////////////////////////////////// CHECKBOX SINGLE
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          valuePropName="checked" // https://github.com/ant-design/ant-design/issues/8261#issuecomment-643972464
                          rules={[  // https://github.com/ant-design/ant-design/issues/8261#issuecomment-439833733
                            {required: field.required, type: "boolean", transform: value => (value || undefined)}
                          ]} 
                        >
                          <Checkbox />
                        </Form.Item>
                      )
                    case "form-data.checkbox-multi" :   //////////////////////////////////////////////////////////////////////////////// CHECKBOX MULTI
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[
                            {type: "array", min: field.min_check, max: field.max_check}
                          ]}
                        >
                          <Checkbox.Group>
                            {field.options.map((option, option_index) => (
                              <React.Fragment key={`field-${field_index}_option-${option_index}`}>
                                <Checkbox value={option.value} disabled={option.disabled} checked={true}>
                                  {option.text || ""}
                                </Checkbox>
                                <br />
                              </React.Fragment>
                            ))}
                          </Checkbox.Group>
                        </Form.Item>
                      )
                    case "form-data.radio" :            //////////////////////////////////////////////////////////////////////////////// RADIO
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[
                            {type: "string"},
                            {required: field.required}
                          ]}
                        >
                          <Radio.Group>
                            {field.options.map((option, option_index) => (
                              <React.Fragment key={`field-${field_index}_option-${option_index}`}>
                                <Radio value={option.value} disabled={option.disabled}>
                                  {option.text || ""}
                                </Radio>
                                <br />
                              </React.Fragment>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      )
                    case "form-data.select" :           //////////////////////////////////////////////////////////////////////////////// SELECT
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[
                            {
                              type: field.mode === "single" ? "string" : field.mode === "multiple" || field.mode === "tags" ? "array" : "string", 
                              min: field.mode === "single" ? undefined : field.min_select, 
                              max: field.mode === "single" ? undefined : field.max_select
                            }, 
                            {
                              required: field.required
                            }
                          ]}
                        >
                          <Select
                            mode={field.mode === "single" ? undefined : field.mode}
                            allowClear={field.allowClear}
                            showSearch={field.showSearch}
                            optionFilterProp={"children"}
                          >
                            {field.options.map((option, option_index) => (
                              <Option key={`field-${field_index}_option-${option_index}`} value={option.value} disabled={option.disabled} selected={option.selected}>
                                {option.text || ""}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )
                    case "form-data.date-picker" :      //////////////////////////////////////////////////////////////////////////////// DATE PICKER
                      const date_from = field.allowed_date_from || undefined
                      const date_to = field.allowed_date_to || undefined
                      function disabledDate(current) {
                        const _from = moment(date_from) || undefined
                        const _current = current || undefined
                        const _to = moment(date_to) || undefined
                        if(!_from){
                          return (_to.isSameOrAfter(_current))
                        }
                        if(!_to){
                          return (_from.isSameOrBefore(_current))
                        }
                        if(_from && _to){
                          return !(_from.isSameOrBefore(_current) && _to.isAfter(_current))
                        }
                        
                      }
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[
                            {type: field.type === "range" ? "array" : "date"}, {required: field.required}
                          ]}
                        >
                          {
                            field.type === "range" ? <RangePicker locale={locale} showTime={field.showTime} disabledDate={disabledDate} /> :
                            field.type === "date" ? <DatePicker locale={locale} showTime={field.showTime} disabledDate={disabledDate} /> :
                            <DatePicker />
                          }
                        </Form.Item>
                      )
                    case "form-data.image" :            //////////////////////////////////////////////////////////////////////////////// IMAGE
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title || ":"}
                        >
                          <Image width={"33.33%"} height={"auto"} src={`${process.env.REACT_APP_STRAPI_HOST_NAME}${field.image.url}`} fallback={placeholderImage} alt={field.alt || ""} />
                        </Form.Item>
                      )
                    case "form-data.header-text" :      //////////////////////////////////////////////////////////////////////////////// TEXT
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title || ":"}
                        >
                          <Typography.Title level={parseInt(field.size.match(/\d/g).join("")) || undefined}>{field.text}</Typography.Title>
                        </Form.Item>
                      )
                    case "form-data.paragraph" :        //////////////////////////////////////////////////////////////////////////////// RICH TEXT
                      const ImageWithSrc = ({src, ...props}) => (
                        <div>
                          <img src={`${process.env.REACT_APP_STRAPI_HOST_NAME}${src}`} width={"100%"} {...props} />
                        </div>
                      )
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title || ":"}
                        >
                          <Markdown 
                            options={{
                              wrapper: 'article',
                              overrides: {
                                img: { component: ImageWithSrc } //https://github.com/probablyup/markdown-to-jsx/issues/293#issuecomment-597260862
                              }
                            }}
                          >
                            {field.rich_text}
                          </Markdown>
                        </Form.Item>
                      )
                    case "form-data.files" :              //////////////////////////////////////////////////////////////////////////////// FILES
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title}
                          name={field.name}
                          rules={[
                            {type: field.max_files_count === 1 ? "object" : "array"}, {required: field.required}
                          ]}
                        >
                          <Upload
                            name={'files'}
                            action={`${process.env.REACT_APP_STRAPI_HOST_NAME}/upload`}
                          >
                            <Button icon={<UploadOutlined />}>Browse</Button>
                          </Upload>
                        </Form.Item>
                      )
                    default :                             //////////////////////////////////////////////////////////////////////////////// FALLBACK
                      console.error(`no field.__component.${field.__component} found`)
                      return (
                        <Form.Item
                          key={`field-${field_index}`}
                          label={field.title || ":"}
                        >
                          <Empty 
                            key={`field-${field_index}`}
                            description={
                              <span>
                                No field "{field.__component}" found. Please see console.
                              </span>
                            }
                          />
                        </Form.Item>
                      )
                    // END CASE
                  }
                })
              }
              { // submit button
                !formData.submit_button ? 
                <Form.Item
                  key={`submit-button-form-${formData.internal_name}`}
                  label={":"}
                >
                  <Button 
                    htmlType="submit" 
                    type={"primary"} 
                  >
                    {"Submit"}
                  </Button>
                </Form.Item>   
                :
                <Form.Item
                  key={`submit-button-form-${formData.internal_name}`}
                  label={":"}
                >
                  <Button 
                    htmlType="submit" 
                    type={!formData.submit_button.type || "primary"} 
                    size={formData.submit_button.size || "middle"} 
                    block={formData.submit_button.block_button || false} 
                    danger={formData.submit_button.danger_button || false} 
                    ghost={formData.submit_button.ghost_button || false}
                  >
                    {formData.submit_button.text || "Submit"}
                  </Button>
                </Form.Item>  
              }
            </>
          }
        </Form>
      </>
      :
      <></>
    }
    </>
  )
}