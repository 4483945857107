import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'

const Home = (props) => {
    return(
        <>
        <Row>
            <Col span={244}>
                <Link to="/preregister/reg">ลงทะเบียน ?</Link>
            </Col>
        </Row>
        </>
        
    )
}

export default Home