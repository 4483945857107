import React from 'react';
import Views from './Views';
import './App.css';

function App() {
  
  return (
    <>
      <Views />
    </>
  );
}

export default App;