import React, { useEffect } from 'react'
import {
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom"
import FormBody from './FormBody'
import FormList from './FormList'
import FormSubmission from './FormSubmission'
import { Row, Col, Card, Result } from 'antd'

function Form(props){

  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  
  return(
    <Row>
      <Col span={24}>
        <Switch>
          <Route exact path={path}>
            <h1>Form Portal</h1>
            <Result status="404" title="Sorry. But you came to the wrong neighborhood" subTitle="There's a Form list down there 👇" />
            <Card title="Form List">
              <FormList />
            </Card>
          </Route>
          <Route path={`${path}/list`}>
            <FormList />
          </Route>
          <Route path={`${path}/submissions`} component={FormSubmission} />
          <Route path={`${path}/:formId`} component={FormBody} />
        </Switch>
      </Col>
    </Row>
    
  )
}

export default Form