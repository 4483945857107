import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Tooltip, Row, Col, Checkbox, Button, Upload, message, Select } from 'antd';
import { QuestionCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';

const { Option } = Select;
const { Dragger } = Upload;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
  style: {
    margin: "10px"
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const DemoRegister = (props) => {
  const history = useHistory()
  const [form] = Form.useForm();
  const [uploadStatus, changeUploadStatus] = useState(null)
  const [isLoading, changeLoading] = useState(false)

  const onFinish = async (values) => {
    if (uploadStatus === "uploading") {
      message.info("กรุณารอการอัพโหลดสำเร็จ")
      return;
    }
    if (uploadStatus === "error") {
      message.error("เกิดข้อผิดพลาดในการอัพโหลดไฟล์ กรุณาลองอัพโหลดใหม่")
      return;
    }
    if (uploadStatus === "done") {
      changeLoading(true)
      const { firstname, lastname, phone, email, lineid, bike_model, whentobuy, saler_name, drivingLicense } = values
      if(drivingLicense.length > 1){
        message.error("ไฟล์ใบขับขี่มีมากเกิน 1 ภาพ กรุณาลบ")
        changeLoading(false)
        return
      }
      const driving_license_url = drivingLicense[0].response["url"]
      const driving_license_b64 = drivingLicense[0].response["base64"]
      const strBikeModel = bike_model.join(";")
      try {
        const request = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/4759029/d741e32a-71d8-4a01-b4d4-af5c65692388`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "fields": [
            {
              "name": "firstname",
              "value": firstname
            },
            {
              "name": "lastname",
              "value": lastname
            },
            {
              "name": "email",
              "value": email
            },
            {
              "name": "phone",
              "value": phone
            },
            {
              "name": "lineid",
              "value": lineid ? lineid : ""
            },
            {
              "name": "driving_license",
              "value": driving_license_url
            },
            {
              "name": "bike_model",
              "value": strBikeModel
            },
            {
              "name": "whentobuy",
              "value": whentobuy
            },
            {
              "name": "saler_name",
              "value": saler_name
            }
          ],
          "context": {
            "pageUri": window.location.href,
            "pageName": document.title
          }
        })
      })
      if(!request.ok){
        changeLoading(false)
        throw request.status
      }
      const objectResponse = await request.json()
      if(objectResponse.inlineMessage){
        changeLoading(false)
        history.push({
          pathname: '/showroom/demosignagreement',
          state: {firstname, lastname, phone, email, lineid, bike_model: strBikeModel, whentobuy, saler_name, driving_license_url: driving_license_url, driving_license_b64: driving_license_b64}
        })
      }
    } catch(err) {
      message.error(err)
      console.error(err)
    }
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadProps = {
    name: 'files',
    multiple: false,
    action: `${process.env.REACT_APP_API_HOST_NAME}/event/showroom/register/handleupload`,
    accept: 'image/*',
    onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        changeUploadStatus(status)
        changeLoading(true)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        changeUploadStatus(status)
        changeLoading(false)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        changeUploadStatus(status)
        changeLoading(false)
      }
    },
  };

  return (
    <main className="Main" style={{padding: "5rem 0"}}>
      <Helmet>
        <title>แบบฟอร์มลงทะเบียนทดสอบขับขี่รถจักรยานยนต์</title>
      </Helmet>
      <Row justify={"center"} align={"middle"}>
        <Col>
          <h1>แบบฟอร์มลงทะเบียนทดสอบขับขี่รถจักรยานยนต์</h1>
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"}>
        <Col xs={24} sm={20} md={16} lg={12} >
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              label={
                <span>
                  ชื่อจริง - นามสกุล&nbsp;
                    <Tooltip title="เพื่อออกเอกสารสำหรับการลงทะเบียนทดลองขับขี่">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              style={{ marginBottom: 0 }}
              rules={[{ required: true }]}
            >
              <Form.Item
                name="firstname"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                rules={[{required: true, message: "กรุณากรอกชื่อจริง"}]}
              >
                <Input placeholder="ชื่อจริง" />
              </Form.Item>
              <Form.Item
                name="lastname"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกนามสกุล"
                  }
                ]}
              >
                <Input placeholder="นามสกุล" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="phone"
              label="เบอร์โทรศัพท์"
              rules={[{ required: true, message: 'กรุณาใส่เบอร์โทรศัพท์' }]}
            >
              <Input style={{ width: '100%' }} placeholder={"เบอร์โทรศัพท์"} />
            </Form.Item>

            <Form.Item
              name="email"
              label={
                <span>
                  อีเมล
                </span>
              }
              rules={[
                { type: 'email', message: 'กรุณากรอกอีเมลที่ถูกต้อง' }, 
                { required: true, message: 'กรุณากรอกอีเมล' },
              ]}
            >
              <Input placeholder={"jon@example.com"} />
            </Form.Item>

            <Form.Item
              name="lineid"
              label={
                <span>
                  LINE ID (ถ้ามี)
                </span>
              }
              rules={[
                { required: false },
              ]}
            >
              <Input placeholder={"LINE ID"} />
            </Form.Item>

            <Form.Item name="bike_model" label="รุ่นรถที่ต้องการทดสอบ" rules={[{ required: true, message: "กรุณาเลือกรุ่นรถอย่างน้อย 1 รุ่น"}]}>
              <Checkbox.Group>
                <Row>
                  <Col span={24}>
                    <Checkbox value="z400" style={{ lineHeight: '32px' }}>
                      Z400
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="ninja400" style={{ lineHeight: '32px' }}>
                      Ninja 400
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="zx-6r" style={{ lineHeight: '32px' }}>
                      Ninja ZX-6R
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="versys650" style={{ lineHeight: '32px' }}>
                      Versys 650
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="z900" style={{ lineHeight: '32px' }}>
                      Z900 2020
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="z900rs" style={{ lineHeight: '32px' }}>
                      Z900RS
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              name="whentobuy"
              label="มีแผนออกรถเมื่อใด ?"
              hasFeedback
              rules={[{ required: true, message: 'กรุณาเลือกตัวเลือก' }]}
            >
              <Select placeholder="มีแผนออกรถเมื่อใด ?">
                <Option value="ภายในเดือนนี้">ภายในเดือนนี้</Option>
                <Option value="ภายในสามเดือน">ภายในสามเดือน</Option>
                <Option value="ภายในสี่เดือนถึงหนึ่งปี">ภายในสี่เดือนถึงหนึ่งปี</Option>
                <Option value="ยังไม่แน่ใจ">ยังไม่แน่ใจ</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="saler_name"
              label={
                <span>
                  เซลที่ดูแล
                </span>
              }
              rules={[
                { required: true, message: "กรุณาระบุชื่อเซลที่ดูแล" },
              ]}
            >
              <Input placeholder={"เซล..."} />
            </Form.Item>

            <Form.Item label="ใบขับขี่">
              <Form.Item name="drivingLicense" valuePropName="fileList" getValueFromEvent={normFile} noStyle rules={[{ required: true, message: "กรุณาอัพโหลดภาพใบขับขี่ของผู้ที่ขับขี่" }]}>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">คลิกหรือลากไฟล์มาที่นี่เพื่ออัพโหลด</p>
                  <p className="ant-upload-hint">เฉพาะไฟล์ภาพ</p>
                </Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button  type="primary" htmlType="submit" loading={isLoading}>
                ลงทะเบียน
              </Button>
            </Form.Item>
          </Form>

        </Col>
      </Row>
    </main>
  )
}

export default DemoRegister;