import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, message, Button, Skeleton, Result, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SignaturePad from 'react-signature-pad-wrapper';

const { Text } = Typography;

const Paragraph = styled.p`
    text-indent: 2rem;
    text-align: justify;
    text-justify: inter-word;
    margin-top: 10px;
`
const Header = styled.h1`
    text-align: center;
    margin: 10px;
`
const SignatureContainer = styled.div`
    background-color: white;
    width: 100%;
    border: 5px solid black;
`

class DemoSignAgreement extends Component{
    constructor(props){
        super(props)
        this.state = {
            signature: null,
            attendee: {
                firstname: null,
                lastname: null,
                phone: null,
                email: null,
                lineid: null,
                driving_license_url: null,
                driving_license_b64: null,
                bike_model: null,
                whentobuy: null,
                saler_name: null
            },
            isLoading: false
        }
    }
    async componentDidMount(){
        try {
            const { state } = this.props.history.location;
            if(!state){
                message.error("not found passed props")
                throw {status: "error", reason: "not found passed props"}
            }
            const { firstname, lastname, phone, email, lineid, bike_model, whentobuy, saler_name, driving_license_url, driving_license_b64 } = state
            this.setState({
                ...this.state.signature,
                ...this.state.isLoading,
                attendee: {
                    firstname: firstname,
                    lastname: lastname,
                    phone: phone,
                    email: email,
                    lineid: lineid ? lineid : "",
                    driving_license_url: driving_license_url,
                    driving_license_b64: driving_license_b64,
                    bike_model: bike_model,
                    whentobuy: whentobuy,
                    saler_name: saler_name
                }
            })
        } catch (err) {
            message.error("Something went wrong 😅 Error: ", err)
        }
    }
    clearSignature = () => {
        this.signaturePad.clear();
    }
    confirmRegister = async () => {
        if(this.signaturePad.isEmpty()) {
            message.error("กรุณาลงลายมือชื่อ")
        } else {
            try {
                this.setState({isLoading: true})
                const dataUrl = await this.signaturePad.toDataURL();
                const uploader = await fetch(`https://${process.env.REACT_APP_API_HOST_NAME}/event/showroom/register/demosignup`,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user: this.state.attendee,
                        sign: dataUrl
                    })
                })
                const responseJson = await uploader.json();
                if(responseJson.status === "success"){
                    message.success("success")
                    this.setState({isLoading: false}, () => {
                        this.props.history.push({
                            pathname: '/showroom/democomfirmed',
                            state: {url: responseJson.url}
                        })
                    })
                } else {
                    this.setState({isLoading: false}, () => {
                        message.error(`status: ${responseJson.status}\nreason: ${responseJson.reason}`)
                    })
                }
            } catch (err) {
                message.error(err)
            }
            
        }
        this.setState({
            isLoading: false
        })
    }
    render(){
        const { firstname, lastname, driving_license_url } = this.state.attendee;
        return (
            !this.props.history.location.state ? 
                <Result status="warning" title="คุณเข้ามาที่หน้านี้อย่างไม่ถูกต้องนะ" extra={<Link to={"/showroom/demoregister"}><Button type="primary" key="back">กลับไปลงทะเบียน</Button></Link>} /> 
            :
                <Row align={"middle"} style={{marginTop: "20px", height: "100%"}}>
                    <Col span={24}>
                        <Row justify={"center"} align={"middle"} style={{margin: "10px 0px", padding: "10px 0px"}}>
                            <Col xs={22} sm={20} md={16} lg={12} >
                                <Helmet>
                                    <title>แบบฟอร์มลงทะเบียนทดสอบขับขี่รถจักรยานยนต์</title>
                                </Helmet>
                                <Header>
                                    แบบฟอร์มขอทดสอบขับขี่
                                </Header>
                                <div style={{margin: "20px 0"}}>
                                {
                                    !driving_license_url ? 
                                        <Skeleton active /> 
                                    : 
                                        <img src={driving_license_url} alt={"driving_license"} width="100%" />
                                }
                                </div>
                                
                                {
                                    !firstname || !lastname ? 
                                        <Skeleton active /> 
                                    : 
                                    <>
                                        <Paragraph>
                                            ข้าพเจ้า {firstname ? firstname : ""} {lastname ? lastname : ""} มีความประสงค์ที่จะขอยืมรถจักรยานยนต์
                                            จากทางบริษัท เรียล โมโตสปอร์ต จำกัด เพื่อทดลองขับขี่ประกอบการตัดสินใจเลือกซื้อ โดยจะปฏิบัติตามข้อกําหนดของทางบริษัทฯ
                                        </Paragraph>
                                        <Paragraph>
                                            ในระหว่างการทดลองขับขี่หากมีความเสียหายใดเกิดขึ้นกับรถจักรยานยนต์ที่ยืมหรือแก่บุคคลใด
                                            เนื่องจากข้าพเจ้าไม่ปฏิบัติตามเงื่อนไข หรือประมาทเลินเล่อ ก่อให้เกิดความเสียหายอย่างใดอย่างหนึ่งขึ้นกับรถ
                                            จักรยานยนต์ที่ยืม หรือแก่ผู้อื่น ข้าพเจ้า ยินดีรับผิดชอบชดใช้ค่าเสียหายที่เกิดขึ้นทั้งหมด ตามที่บริษัทฯจะเห็นสม
                                            ควร
                                        </Paragraph>
                                    </>
                                }

                            </Col>
                        </Row>
                        <Row justify={"center"} align={"middle"} style={{margin: "10px 0px 40px 0px", padding: "10px 0px 40px 0px"}}>
                            <Col xs={22} sm={20} md={16} lg={12}>
                                <SignatureContainer>
                                    <SignaturePad ref={ref => this.signaturePad = ref} />
                                </SignatureContainer>
                                <Text type="secondary">เมื่อคุณลงลายมือชื่อนี้และกดลงทะเบียน เป็นการยอมรับข้อตกลงดังกล่าว และยืนยันว่าภาพสำเนาใบขับขี่ถูกต้อง</Text>
                            </Col>
                        </Row>
                        <Row justify={"center"} align={"middle"} style={{margin: "10px 0px 40px 0px", padding: "10px 0px 40px 0px"}}>
                            <Col xs={22} sm={20} md={16} lg={12}>
                                <Button type="secondary" onClick={this.clearSignature}>
                                    ล้าง
                                </Button>
                                <Button type="primary" style={{float: "right"}} loading={this.state.isLoading} onClick={this.confirmRegister}>
                                    ลงทะเบียน
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            
        )
    }
}

export default DemoSignAgreement;