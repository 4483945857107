import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Checkbox,
  Button,
  Upload,
  message
} from 'antd';
import { QuestionCircleOutlined, InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
  style: {
    margin: "10px"
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const PreRegister = () => {
  const [form] = Form.useForm();

  const [uploadStatus, changeUploadStatus] = useState(null)
  const [isLoading, changeLoading] = useState(false)

  const onFinish = values => {
    if (uploadStatus === "uploading") {
      message.info("กรุณารอการอัพโหลดสำเร็จ")
      return;
    }
    if (uploadStatus === "error") {
      message.error("เกิดข้อผิดพลาดในการอัพโหลดไฟล์ กรุณาลองอัพโหลดใหม่")
      return;
    }
    if (uploadStatus === "done") {
      alert("All OK")
    }
    console.log('Received values of form: ', values);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadProps = {
    name: 'files',
    multiple: false,
    action: 'http://localhost:1337/upload',
    accept: 'image/*',
    onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        changeUploadStatus(status)
        changeLoading(true)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        changeUploadStatus(status)
        changeLoading(false)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        changeUploadStatus(status)
        changeLoading(false)
      }
    },
  };

  const submitButtonProps = {
    type: "primary",
    htmlType: "submit"
  }

  return (
    <>
      <Row justify={"center"} align={"middle"}>
        <Col>
          <h1>ลงทะเบียนล่วงหน้า</h1>
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"}>
        <Col xs={24} sm={20} md={16} lg={12} >
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              label={
                <span>
                  ชื่อจริง - นามสกุล&nbsp;
                    <Tooltip title="เพื่อออกเอกสารสำหรับการลงทะเบียนทดลองขับขี่">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              style={{ marginBottom: 0 }}
              rules={[{ required: true }]}
            >
              <Form.Item
                name="firstname"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อจริง"
                  }
                ]}
              >
                <Input placeholder="ชื่อจริง" />
              </Form.Item>
              <Form.Item
                name="lastname"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกนามสกุล"
                  }
                ]}
              >
                <Input placeholder="นามสกุล" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="phone"
              label="เบอร์โทรศัพท์"
              rules={[{ required: true, message: 'กรุณาใส่เบอร์โทรศัพท์' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="email"
              label={
                <span>
                  อีเมล&nbsp;
                  <Tooltip title="กรอกเพื่อรับ QR CODE และรายละเอียดการลงทะเบียนผ่าน Email">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  type: 'email',
                  message: 'กรุณากรอกอีเมลที่ถูกต้อง',
                },
                {
                  required: false
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="ใบขับขี่">
              <Form.Item name="ridingLc" valuePropName="fileList" getValueFromEvent={normFile} noStyle rules={[{ required: true, message: "กรุณาอัพโหลดภาพใบขับขี่ของผู้ที่ขับขี่" }]}>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">คลิกหรือลากไฟล์มาที่นี่เพื่ออัพโหลด</p>
                  <p className="ant-upload-hint">เฉพาะไฟล์ภาพ</p>
                </Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                { validator: (_, value) => value ? Promise.resolve() : Promise.reject('กรุณายอมรับข้อตกลง') },
              ]}
              {...tailFormItemLayout}
            >
              <Checkbox>
                ฉันยอมรับ <a href="https://www.google.com/">ข้อตกลง</a>
              </Checkbox>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button {...submitButtonProps} loading={isLoading}>
                ลงทะเบียน
              </Button>
            </Form.Item>
          </Form>

        </Col>
      </Row>
    </>
  )
}

export default PreRegister;