import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, CardTitle, CardSubtitle, CardImg, CardText, Input, CustomInput, CardBody, CardHeader, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col, Container, Spinner, FormText, Table } from 'reactstrap'
import styled from 'styled-components'
import introImage from './cover.jpg'

const NP = styled.span`
color: white;
font-weight: 700;
background-color: black;
padding: 3px;
border-radius: 5px;
`
const EachPromotion = styled.div`
display: block;
border-radius: 5px;
border: 1px solid #00ff95;
padding: 10px;
margin: 10px 0;
background-color: ${props => props.selected ? "#c7ffe8" : "#f7fffc"};
transition: all .2s ease-in-out;
cursor: pointer;
transform: ${props => props.selected ? "scale(1.02)" : "scale(1)"};
&:hover{
  transform: scale(1.02)
}
`

function Introduction(){
  return (
    <>
      <CardImg draggable={false} top width="100%" src={introImage} alt={"โปรแกรมประเมินผลไฟแนนซ์เบื้องต้น"} />
      <CardBody>
        <CardTitle tag="h3">โปรแกรมประเมินผลไฟแนนซ์เบื้องต้น</CardTitle>
        <CardSubtitle tag="h6" className="text-muted">โปรแกรมประเมินผลไฟแนนซ์เบื้องต้นเป็นเพียงโปรแกรมช่วยคำนวณด้วยระบบอัตโนมัติเท่านั้นซึ่งเป็นค่าที่ไม่เป็นทางการ การประเมินผลไฟแนนซ์อย่างเป็นทางการจะทำโดยเจ้าหน้าที่ประเมินผลไฟแนนซ์จากสถาบันการเงินเท่านั้น</CardSubtitle>
        <CardText className="text-muted" style={{fontSize: "0.8rem"}}>V. 0.2</CardText>
      </CardBody>
    </>
  )
}

function Promotions({promotionData, setPromotionData, bikeData, pageLock, setPage}){
  function isSelected(_promo, _each){
    if(!_promo){ // not selected
      return false
    } 
    else if(_promo){ //selected
      if(_promo.id && _each.id){
        if(_promo.id === _each.id){
          return true
        } 
        else {
          return false
        }
      }
      else {
        return false
      }
    }
  }
  return (
    <>
      <CardHeader tag="h3">เลือกโปรโมชั่น</CardHeader>
      <CardBody>
        <div>
          {
            !bikeData.promotion ?
            <EachPromotion onClick={() => setPromotionData({})}>ไม่มีโปรโมชั่น</EachPromotion> :
            bikeData.promotion.promotions.length <= 0 ?
              <EachPromotion onClick={() => setPromotionData({})}>ไม่มีโปรโมชั่น</EachPromotion> :
            bikeData.promotion.promotions.map((each, eachIndex) => {
              return (
                <EachPromotion  
                  key={each.id} id={each.id} 
                  data-promo={JSON.stringify(each)} 
                  selected={!promotionData ? false : promotionData.id && each.id ? promotionData.id == each.id ? true : false : false} 
                  onClick={(e) => setPromotionData(JSON.parse(e.currentTarget.getAttribute("data-promo")))
                  }
                >
                  {each.name ? <h3>Package {each.name}</h3> : <></>}
                  {each.discount ? <h5>ส่วนลด {new Intl.NumberFormat().format(each.discount)} บาท</h5> : <></>}
                  {each.stepup_discount ? <h5>ส่วนลด Stepup {new Intl.NumberFormat().format(each.stepup_discount)} บาท {each.stepup_discount_condition ? `(${each.stepup_discount_condition})` : ""}</h5> : <></>}
                  {each.insurance_firstclass ? <div>ฟรี ประกันชั้น 1</div> : <></>}
                  {each.insurance_lossbike ? <div>ฟรี ประกันรถหาย </div> : <></>}
                  {each.registeration_fee ? <div>ฟรี จดทะเบียน</div> : <></>}
                  {each.act_fee ? <div>ฟรี พ.ร.บ.</div> : <></>}
                  {each.extra_warranty_year ? <div>เพิ่ม ประกันคุณภาพเป็น {each.extra_warranty_year} ปี</div> : <></>}
                  {
                    each.premiums.length === 0 ? <></> : 
                    <div>
                      <h5>ของสมนาคุณ</h5>
                    {each.premiums.map(item => {
                      return (
                        <div>
                          {/* <div className="inline-block">{item.image ? <img className={"img-cropped"} src={`${process.env.REACT_APP_STRAPI_HOST_NAME}${item.image.url}`} width="100%" />: ""}</div> */}
                          <div className="inline-block">● {item.name} {item.value ? `มูลค่า ${new Intl.NumberFormat().format(item.value)} บาท` : ""}</div>
                        </div>
                      )
                    })}
                    </div>
                  }
                  {
                    !each.special_interest ? <></> : each.special_interest.length > 0 ? 
                      <div>
                        <h5>ดอกเบี้ยพิเศษ: </h5>
                        <Table bordered size="sm">
                          <thead>
                            <tr>
                              <th>ดาวน์</th>
                              <th>ดอกเบี้ย</th>
                              <th>งวด</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              each.special_interest.map(spi => 
                              <tr>
                                <td>{spi.Down_percent != null || spi.Down_percent != undefined ? `${spi.Down_percent} %` : ""}</td>
                                <td>{spi.interest != null || spi.interest != undefined ? `${spi.interest} %` : ""}</td>
                                <td>{spi.max_payperiod_month ? `${spi.max_payperiod_month}` : ""}</td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                      </div>
                    : 
                    <></>
                  }
                </EachPromotion>
              )
            })
          }
        </div>
      </CardBody>
    </>
  )
}

function DownpaymentPayperiod({userData, setUserData, promotionData, interest, price}){
  const special_interest_obj = promotionData.special_interest ? promotionData.special_interest.reduce((prev, curr) => ({...prev, [curr.Down_percent]: curr.interest}) ,{}) : {}
  const { downPayment, payPeriod } = userData
  const actualInterest = interest.filter(item => {
    let newA = {0:0,5:5,10:10,15:15,20:20,25:20,30:30,35:30,40:30,45:30,50:30}
    let downpercent = newA[downPayment]
    return item.down_percent === downpercent
  })[0]
  const overrideInterest = {
    ...actualInterest,
    interest: special_interest_obj[downPayment] === undefined ? actualInterest.interest : special_interest_obj[downPayment]
  }
  const totalDiscount = Number((promotionData.discount || 0)+(promotionData.stepup_discount || 0))
  const down_payment_value = Number((price/100)*downPayment) //ยอดดาวน์
  const down_percent = Number(downPayment) //ดาวน์ %
  const loan_value = Number(price-((price/100)*downPayment)) //ยอดจัด
  const pay_period_month = Number(payPeriod) //งวด เดือน
  const pay_period_year = Number(pay_period_month/12) //งวด ปี
  const interest_percent = Number(overrideInterest.interest) //ดอกเบี้ย%
  const pay_per_month = Math.ceil((loan_value/100*interest_percent*pay_period_year+loan_value)/pay_period_month)
  useEffect(() => {
    setUserData(prevState => ({...prevState, payPerMonth: pay_per_month}))
  },[pay_per_month])
  return (
    <>
      <CardHeader tag="h3">ปรับแต่งเงินดาวน์และค่างวด</CardHeader>
      <CardBody>
        <div  style={{marginBottom: "50px", textAlign:"left"}} >
          <CardSubtitle tag="h5">ส่วนลด / ดอกเบี้ยพิเศษ</CardSubtitle>
          {!promotionData.discount ? <></> : <div>ส่วนลดเงินดาวน์: <NP>{new Intl.NumberFormat().format(promotionData.discount)}</NP> บาท</div>}
          {!promotionData.stepup_discount ? <></> : <div>ส่วนลด Stepup: <NP>{new Intl.NumberFormat().format(promotionData.stepup_discount)}</NP> บาท</div>}
          {
            !promotionData.special_interest ? <></> : promotionData.special_interest.length > 0 ? 
              <div>
                <div>ดอกเบี้ยพิเศษ: </div>
                <Table bordered size="sm">
                  <thead>
                    <tr>
                      <th>ดาวน์</th>
                      <th>ดอกเบี้ย</th>
                      <th>งวด</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      promotionData.special_interest.map(spi => 
                      <tr>
                        <td>{spi.Down_percent != null || spi.Down_percent != undefined ? `${spi.Down_percent} %` : ""}</td>
                        <td>{spi.interest != null || spi.interest != undefined ? `${spi.interest} %` : ""}</td>
                        <td>{spi.max_payperiod_month ? `${spi.max_payperiod_month}` : ""}</td>
                      </tr>)
                    }
                  </tbody>
                </Table>
              </div>
             : 
            <></>
          }
        </div>
        <div  style={{marginBottom: "50px", textAlign:"left"}}>
          <CardSubtitle tag="h5">จำนวนเงินดาวน์</CardSubtitle>
          <div>ดาวน์: <NP>{down_percent}</NP> % ดอกเบี้ย: <NP>{overrideInterest.interest}</NP> %ต่อปี</div>
          {
            !promotionData.discount ? 
            <div>เงินดาวน์: <NP>{new Intl.NumberFormat().format(down_payment_value)}</NP> บาท </div> : 
            <div>
              ยอดดาวน์รวมส่วนลด: <NP>{(down_payment_value-totalDiscount) < 0 ? 0 : new Intl.NumberFormat().format(down_payment_value-totalDiscount)}</NP> บาท 
            </div>
          }
          <div>ยอดจัด: <NP>{new Intl.NumberFormat().format(loan_value)}</NP> บาท</div>
          <CustomInput type="range" id="downPayment" name="downPayment" 
            min="0" max="50" step="5" 
            defaultValue={downPayment} 
            onChange={(e) => {
              e.persist()
              return setUserData(prevState => ({...prevState, downPayment: Number(e.target.value), payPerMonth: pay_per_month}) )
            }} />
          <div style={{textAlign: "center"}} >
            <span>⟵ เลื่อนเพื่อเลือกเงินดาวน์ ⟶</span>
          </div>
        </div>
        <div  style={{marginBottom: "20px", textAlign:"left"}} >
          <CardSubtitle tag="h5">ระยะเวลาผ่อน</CardSubtitle>
          <div>ผ่อน: <NP>{payPeriod}</NP> งวด | <NP>{payPeriod/12}</NP> ปี</div>
          <div>งวดละ: <NP>{new Intl.NumberFormat().format(pay_per_month)}</NP> บาท</div> {/* (ยอดจัด/100*ดอกเบี้ย*จำนวนปี+ยอดจัด)/จำนวนเดือน */}
          <CustomInput type="range" id="payPeriod" name="payPeriod" 
            min="12" max="60" step="6" 
            defaultValue={payPeriod} 
            onChange={(e) => {
              e.persist()
              return setUserData(prevState => ({...prevState, payPeriod: Number(e.target.value), payPerMonth: pay_per_month})) 
            }} />
          <div style={{textAlign: "center"}} >
            <span>⟵ เลื่อนเพื่อเลือกจำนวนงวด ⟶</span>
          </div>
        </div>
      </CardBody>
    </>
  )
}

function Salary({userData, setUserData, pageLock}){
  const { userSalary } = userData
  return (
    <>
      <CardHeader tag="h3">รายได้</CardHeader>
      <CardBody>
        <CardSubtitle tag="h6">รายได้ต่อเดือน</CardSubtitle>
        <InputGroup>
          <Input placeholder="รายได้ต่อเดือน" type="number" id="salary" name="salary" defaultValue={userSalary} invalid={pageLock} onChange={(e) => setUserData({...userData, userSalary: Number(e.target.value)})} />
          <InputGroupAddon addonType="append">
            <InputGroupText>บาท / เดือน</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </CardBody>
    </>
  )
}

function Jobduration({userData, setUserData, pageLock}){
  const { userJobDuration } = userData
  return (
    <>
      <CardHeader tag="h3">อายุงาน</CardHeader>
      <CardBody>
        <CardSubtitle tag="h6" style={{marginBottom: "15px"}}>{`ระยะเวลาที่ทำงานปัจจุบัน (กรุณากรอกหน่วยเป็น "เดือน")`}</CardSubtitle>
          <div style={{ textAlign: "center"}}>
            <Container>
              <Row>
                <Col sm={3} md={3}></Col>
                <Col sm={6} md={6}>
                  <InputGroup>
                    <Input 
                      type="number" id="jobDuration" name="jobDuration" 
                      defaultValue={userJobDuration} 
                      invalid={pageLock} 
                      onInput={ 
                        (e) => setUserData({...userData, userJobDuration: Number(e.target.value)}) } 
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>เดือน</InputGroupText>
                      <InputGroupText>{`${userJobDuration===undefined ? "x" : (userJobDuration/12).toFixed(1)} ปี`}</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col sm={3} md={3}></Col>
              </Row>
            </Container>
          
          </div>
      </CardBody>
    </>
  )
}

function Credit({userData, setUserData}){
  const { userUsedBankLoan } = userData
  return (
      <>
        <CardHeader tag="h3">ประวัติทางการเงิน</CardHeader>
        <CardBody>
          <FormGroup style={{textAlign: "left"}}>
            <Label for="usedBankLoan">ประวัติการขอสินเชื่อ</Label>
            <Input 
              type="select" 
              name="usedBankLoan" 
              id="usedBankLoan" 
              onChange={(e) => {
                return setUserData({...userData, userUsedBankLoan: e.target.value === 'true' ? true : false})
              }}
              value={userUsedBankLoan}
            >
              <option value={undefined} disabled={true} selected >กรุณาเลือก</option>
              <option value={true} disabled={false} >เคยผ่อนบ้าน / รถ / จักรยานยนต์ / ใช้บัตรเครดิต</option>
              <option value={false} disabled={false} >ไม่เคยใช้ผลิตภัณฑ์ทางการเงิน</option>
            </Input>
          </FormGroup>
        </CardBody>
      </>
  )
}

function CreditOverdue({userData, setUserData}){
  const { hasOverdue } = userData
  return (
    <>
    <CardBody>
      <FormGroup style={{textAlign: "left"}}>
        <Label for="has">ประวัติการค้างชำระ</Label>
        <Input type="select" name="hasOverdue" defaultValue={hasOverdue} id="hasOverdue" onChange={(e) => {
          return setUserData({...userData, hasOverdue: e.target.value === 'true' ? true : false})
        }}>
          <option value={undefined} disabled={true} selected>กรุณาเลือก</option>
          <option value={true} disabled={false} >เคยค้างชำระบัตรเครดิต / ค่างวดรถยนต์ / จักรยานยนต์ / บ้าน ติดต่อกันเกิน 3 เดือน</option>
          <option value={false} disabled={false}>ไม่เคยค้างชำระ</option>
        </Input>
      </FormGroup>
    </CardBody>
  </>
  )
}


function FinishForm({userData, bikeData, setResultId}){
  const history = useHistory()
  const [ Loading, setLoading ] = useState(false)
  async function handleSubmit(e){
    e.preventDefault()
    setLoading(true)
    let data = {...userData, bikeData: bikeData.id}
    const formdata = new FormData(e.target)
    for(const [key,value] of formdata.entries()){
      data = {
        ...data,
        [key]: value
      }
    }
    try{
      const postData = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/finance-calculators/new`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      const response = await postData.text()
      history.push({
        search: `?result=${response}`
      })
      setResultId(response)
      setLoading(false)
    } catch(err){
      console.error(err)
      alert(`เกิดข้อผิดพลาด กรุณา Refresh หน้านี้`)
      setLoading(false)
    }
  }
  return (
    <>
      <CardHeader tag="h3">ขั้นตอนสุดท้าย</CardHeader>
      <CardBody style={{textAlign: "left"}}>
        <div><h5>เกือบจะเสร็จแล้ว อีกขั้นตอนเดียว !</h5></div>
        <div><h6>กรอกข้อมูลเหล่านี้เพื่อดูผลการประเมินไฟแนนซ์ของคุณ</h6></div>
        
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="firstname">ชื่อ</Label>
                <Input type="text" name="firstname" id="firstname" required onInvalid={e => e.target.setCustomValidity('กรุณาใส่ชื่อของคุณ')}  onInput={e => e.target.setCustomValidity("")} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastname">นามสกุล</Label>
                <Input type="text" name="lastname" id="lastname" required onInvalid={e => e.target.setCustomValidity('กรุณาใส่นามสกุลของคุณ')}  onInput={e => e.target.setCustomValidity("")} />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">เบอร์โทรศัพท์</Label>
                <Input type="number" name="phone" id="phone" required onInvalid={e => e.target.setCustomValidity('กรุณาใส่เบอร์โทรของคุณ')}  onInput={e => e.target.setCustomValidity("")} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email">อีเมล</Label>
                <Input type="email" name="email" id="email" required onInvalid={e => e.target.setCustomValidity('กรุณาใส่อีเมลของคุณ')}  onInput={e => e.target.setCustomValidity("")} />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col xs={12}>
              <FormGroup>
                <Label for="whentobuy">มีแผนออกรถเมื่อใด</Label>
                <Input type="select" name="whentobuy" id="whentobuy" required onInvalid={e => e.target.setCustomValidity('กรุณาระบุ')}  onInput={e => e.target.setCustomValidity("")}>
                  <option value="" disabled selected></option>
                  <option value="ภายในเดือนนี้">ภายในเดือนนี้</option>
                  <option value="ภายในสามเดือน">ภายในสามเดือน</option>
                  <option value="ภายในสี่เดือนถึงหนึ่งปี">ภายในสี่เดือนถึงหนึ่งปี</option>
                  <option value="ไม่มีแผนออกรถ">ไม่มีแผนออกรถ</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <FormGroup check>
                <Input type="checkbox" name="consent" id="consent" required onInvalid={e => e.target.setCustomValidity('คุณต้องยอมรับ')}  onInput={e => e.target.setCustomValidity("")} />
                <Label for="consent">ยอมรับ <a href={"https://realmotosports.com/privacy-policy/"} target="_blank">นโยบายความเป็นส่วนตัว</a></Label>
                <FormText>{`
                เมื่อคุณเลือกยอมรับ คุณยินดีที่จะให้เราทำดังต่อไปนี้\n
                1. เราจะเก็บข้อมูลส่วนตัวของท่านเข้าในระบบฐานข้อมูลของเรา
                2. เจ้าหน้าที่ ที่เกี่ยวข้องจะติดต่อกลับ เพื่อเสนอการขายหรือการบริการ ผ่านช่องทางดังนี้ \n
                2.1 ทางโทรศัพท์\n
                2.2 ทางอีเมล
                2.3 ทางแพลตฟอร์มโซเชียลมีเดีย (LINE, Facebook)\n
                3. เราจะใช้ข้อมูลด้านไฟแนนซ์ของคุณโดยไม่รวมข้อมูลที่ระบุตัวตน เช่น ชื่อ-นามสกุล เบอร์โทรศัพท์ อีเมล เพื่อทำการประมวลผลข้อมูล นำมาวิเคราะห์ข้อมูล และสร้างและปรับปรุงผลิตภัณฑ์และบริการของเรา\n
                `}</FormText>
              </FormGroup>
            </Col>
          </Row>
          <div style={{textAlign: "center", margin: "20px 0 0 0"}}>
            <Button color="success" size="lg" type={"submit"} disabled={Loading}>
              {
                Loading === true ?
                <Spinner size={"md"} /> :
                <></>
              }
              <span>ดูผลการประเมิน</span>
            </Button>
          </div>
        </Form>

      </CardBody>
    </>
  )
}


const StepsLength = 7
export { StepsLength, Introduction, Promotions, DownpaymentPayperiod, Salary, Jobduration, Credit, CreditOverdue, FinishForm }