import React, { useState, useEffect } from 'react'
import {
  Link,
  useRouteMatch
} from "react-router-dom"
import { Table, message } from 'antd'

export default function FormList(){
    const [ data, setData ] = useState(null)
    let { url } = useRouteMatch();
    // url.substring(0, url.lastIndexOf('/')) = up one level url ref=https://stackoverflow.com/questions/55858176/go-up-one-level-in-react-router-using-link
    const up = url.substring(0, url.lastIndexOf('/'))
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "Internal Name",
        dataIndex: "internal_name",
        key: "internal_name"
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Slug",
        dataIndex: "slug",
        key: "slug",
        render: (text, record) => (
          <Link to={up === '/form' ? `${up}/${text}` : `form/${text}`}>{text}</Link>
        )
      }
    ]
    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/forms`)
          if(!response.ok){
            throw new Error(await response.text())
          }
          const json = await response.json()
          const mapped = json.map((item,index) => {
            return {
              key: index,
              id: item.id,
              internal_name: item.internal_name,
              name: item.name,
              slug: item.slug
            }
          })
          setData(mapped)
        } catch (error) {
          console.error(error)
          message.error(error.message)
        }
      }
      fetchData()
    },[])
  
    return (
      <>
      {url}
      <Table loading={!data ? true : false} dataSource={data} columns={columns} />
      </>

    )
  }