import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Row, Col, Card } from 'reactstrap'
import { BikeSelector } from './BikeSelector'
import { Question } from './Question'
import { Result } from './Result'
import './style.css'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Finance = (props) => {
  const [ selectedBike, selectBike ] = useState(null)
  const [ resultId, setResultId ] = useState(null)
  const query = useQuery().get("result")
  useEffect(() => {
    if(query){
      setResultId(query)
    }
    if(!query){
      setResultId(null)
    }
  },[selectedBike, resultId, query])

  
  
  async function onSelectBike(value){
    let bike_id = value.currentTarget.id
    try {
      const bikedata = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/motorcycles/${bike_id}`)
      if(!bikedata.ok){
        throw await bikedata.text()
      }
      const bikedataJ = await bikedata.json()
      selectBike(bikedataJ)
    }
    catch(err){
      console.error(err)
    }
  }

  function deselectBike(){
    selectBike(null)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>โปรแกรมประเมินผลไฟแนนซ์อัตโนมัติ Kawasaki Real Motosports</title>
      </Helmet>
      {
        selectedBike === null && resultId === null? 
          <BikeSelector onSelectBike={onSelectBike} onClick={onSelectBike} /> :
        selectedBike != null && resultId === null ?
          <Question bikedata={selectedBike} deselectBike={deselectBike} setResultId={setResultId} /> :
        resultId != null ?
          <Result resultId={resultId} /> :
          <>
            <h1>Not Found</h1>
            <pre>
              <code>
                {JSON.parse({
                  selectBike,
                  resultId
                })}
              </code>
            </pre>
          </>
          
      }
    </>
  )
}

export default Finance