import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const Error404 = ({history}) => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="หน้าที่คุณเข้ามาไม่มีอยู่"
            extra={
                <Button type="primary" onClick={() => history.goBack()} >กลับ</Button>
            }
        />
    )
}

export default Error404