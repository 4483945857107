import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';

class BikeModelSelector extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            bikemodels : []
        }
    }

    componentDidMount(){
        const { BikeData } = this.props
        const bikesArray = BikeData.map(bike => {
            return bike.bikeModel;
        })
        this.setState({
            bikemodels : bikesArray.sort()
        })
    }

    render(){
        const { bikemodels } = this.state
        return (
            <FormGroup>
                <Label for="bikeModel">รุ่นรถที่จะเข้ารับบริการ</Label>
                <Input type="select" name="bikeModel" id="bikeModel" onChange={this.props.BikeModelChange} defaultValue={'0'}>
                    <option value="0" unselectable="true" disabled>กรุณาเลือกรุ่นรถ</option>
                    {   
                        bikemodels.map(bike => {
                            return (
                                <option
                                    key={bike}
                                    value={bike}
                                    >
                                        {bike}
                                    </option>
                            )
                        })
                    }
                </Input>
            </FormGroup>
        )
    }
}

export default BikeModelSelector;
// ({ BikeData, BikeModelChange })
// BikeData.map((bike, index) => {
//     return (
//         <option 
//             key={ BikeData[index].bikeModel } 
//             value={ BikeData[index].bikeModel }
//             >
//                 { BikeData[index].bikeModel }
//         </option>
//     )
// })

// sort bike model
// ninja400 I=>i
// miles must have comma and km.
// สื่อสารให้เข้าใจ ใช้นเ้ำมันเครื่อง
