import React, { useState } from 'react';
import { Row, Col, message, Spin } from 'antd';
import HubspotForm from 'react-hubspot-form';

const NewRegister = (props) => {
    const [ isSubmitting, changeSubmitting ] = useState(false);
    
    const getIdByEmail = async (email, callback) => {
        try {
            const request = await fetch(`http://${process.env.REACT_APP_API_HOST_URI}/event/contact/getvid?email=${email}`)
            const objectResponse = await request.json()
            if(request.status === 200){
              const { vid } = objectResponse;
              callback(vid)
            } else {
                message.error(`${objectResponse.status} ${objectResponse.message}`);
            }
          } catch(err) {
            message.error(err)
            throw err
          }
    }
    return (
        <Spin tip="Loading..." spinning={isSubmitting}>
        <Row align={"middle"} style={{marginTop: "20px", height: "100%"}}>
            <Col span={24}>
                <Row justify={"center"} align={"middle"} style={{marginBottom: "30px", paddingBottom: "30px"}}>
                    <Col xs={23} sm={20} md={16} lg={12} >
                        <h1>Register</h1>
                        <HubspotForm
                            portalId={process.env.REACT_APP_PORTAL_ID}
                            formId={process.env.REACT_APP_FORM_GUID}
                            onSubmit={(array) => {
                                changeSubmitting(true)
                                array.forEach((item, index) => {
                                    if(item.name === "email"){
                                        const email = item.value
                                        getIdByEmail(email,(vid) => {
                                            props.history.push({
                                                pathname: '/postregister/sign2',
                                                query: vid
                                            })
                                        })
                                    }

                                })
                            }}
                            onReady={(form) => console.log(form)}
                            loading={<div>Loading...</div>}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        </Spin>
        
        
    )
}

export default NewRegister;