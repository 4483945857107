export const BikeData = [
  {
      "bikeModel" : "W175",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1137.44,
                  "promo" : 751
              },
              "motul" : {
                  "normal" : 1145.44,
                  "promo" : 755
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  2223.16 ,
                  "promo" : 1837
              },
              "motul" : {
                  "normal" : 2231.16,
                  "promo" : 1841
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  2223.16 ,
                  "promo" : 1837
              },
              "motul" : {
                  "normal" : 2231.16,
                  "promo" : 1841
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  2223.16 ,
                  "promo" : 1837
              },
              "motul" : {
                  "normal" : 2231.16,
                  "promo" : 1841
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  2223.16 ,
                  "promo" : 1837
              },
              "motul" : {
                  "normal" : 2231.16,
                  "promo" : 1841
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  2223.16 ,
                  "promo" : 1837
              },
              "motul" : {
                  "normal" : 2231.16,
                  "promo" : 1841
              }
          }
      }
  },
  {
    "bikeModel" : "W250",
    "package" : "A",
    "periodic" : {
        "1000" : {
            "elf" : {
                "normal" : 1137.44,
                "promo" : 751
            },
            "motul" : {
                "normal" : 1145.44,
                "promo" : 755
            }
        },
        "12000" : {
            "elf" : {
                "normal" :  2223.16 ,
                "promo" : 1837
            },
            "motul" : {
                "normal" : 2231.16,
                "promo" : 1841
            }
        },
        "24000" : {
            "elf" : {
                "normal" :  2223.16 ,
                "promo" : 1837
            },
            "motul" : {
                "normal" : 2231.16,
                "promo" : 1841
            }
        },
        "36000" : {
            "elf" : {
                "normal" :  2223.16 ,
                "promo" : 1837
            },
            "motul" : {
                "normal" : 2231.16,
                "promo" : 1841
            }
        },
        "48000" : {
            "elf" : {
                "normal" :  2223.16 ,
                "promo" : 1837
            },
            "motul" : {
                "normal" : 2231.16,
                "promo" : 1841
            }
        },
        "60000" : {
            "elf" : {
                "normal" :  2223.16 ,
                "promo" : 1837
            },
            "motul" : {
                "normal" : 2231.16,
                "promo" : 1841
            }
        }
    }
    },
  {
      "bikeModel" : "Z250SL",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1293.09,
                  "promo" : 907
              },
              "motul" : {
                  "normal" : 1301.09,
                  "promo" : 911
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  3462.46 ,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  3462.46 ,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  3462.46 ,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  3462.46 ,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  3462.46 ,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          }
      }
  },
  {
      "bikeModel" : "Ninja 250SL",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1293.09,
                  "promo" : 907
              },
              "motul" : {
                  "normal" : 1301.09,
                  "promo" : 911
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  3462.46,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  3462.46,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  3462.46,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  3462.46,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  3462.46,
                  "promo" : 3076
              },
              "motul" : {
                  "normal" : 3470.46,
                  "promo" : 3081
              }
          }
      }
  },
  {
      "bikeModel" : "Z250 2013-2017",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1362
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1370
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          }
      }
  },
  {
      "bikeModel" : "Ninja 250 2013 - 2017",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1362
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1370
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          }
      }
  },
  {
      "bikeModel" : "KLX250",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1293.09,
                  "promo" : 907
              },
              "motul" : {
                  "normal" : 1301.10,
                  "promo" : 911
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  3034.22,
                  "promo" : 2648
              },
              "motul" : {
                  "normal" : 3042.22,
                  "promo" : 2652
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  3321.22,
                  "promo" : 2935
              },
              "motul" : {
                  "normal" : 3329.22,
                  "promo" : 2939
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  3034.22,
                  "promo" : 2648
              },
              "motul" : {
                  "normal" : 3042.22,
                  "promo" : 2652
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  3321.22,
                  "promo" : 2935
              },
              "motul" : {
                  "normal" : 3329.22,
                  "promo" : 2939
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  3034.22,
                  "promo" : 2648
              },
              "motul" : {
                  "normal" : 3042.22,
                  "promo" : 2652
              }
          }
      }
  },
  {
      "bikeModel" : "D-TRACKER X 250",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1293.09,
                  "promo" : 907
              },
              "motul" : {
                  "normal" : 1301.10,
                  "promo" : 911
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  3034.22,
                  "promo" : 2648
              },
              "motul" : {
                  "normal" : 3042.22,
                  "promo" : 2652
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  3321.22,
                  "promo" : 2935
              },
              "motul" : {
                  "normal" : 3329.22,
                  "promo" : 2939
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  3034.22,
                  "promo" : 2648
              },
              "motul" : {
                  "normal" : 3042.22,
                  "promo" : 2652
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  3321.22,
                  "promo" : 2935
              },
              "motul" : {
                  "normal" : 3329.22,
                  "promo" : 2939
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  3034.22,
                  "promo" : 2648
              },
              "motul" : {
                  "normal" : 3042.22,
                  "promo" : 2652
              }
          }
      }
  },
  {
      "bikeModel" : "Versys-X 300",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1360
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1370
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  5753.06,
                  "promo" : 5367
              },
              "motul" : {
                  "normal" : 5765.06,
                  "promo" : 5375
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  5753.06,
                  "promo" : 5367
              },
              "motul" : {
                  "normal" : 5765.06,
                  "promo" : 5375
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          }
      }
  },
  {
      "bikeModel" : "Z300",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1362
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1370
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          }
      }
  },
  {
      "bikeModel" : "Ninja 300",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1362
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1370
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  4653.10,
                  "promo" : 4267
              },
              "motul" : {
                  "normal" : 4665.10,
                  "promo" : 4275
              }
          }
      }
  },
  {
    "bikeModel" : "Ninja ZX-25R",
    "package" : "A",
    "periodic" : {
        "1000" : {
            "elf" : {
                "normal" : 2133.57,
                "promo" : 1748
            },
            "motul" : {
                "normal" : 2149.57,
                "promo" : 1760
            }
        },
        "12000" : {
            "elf" : {
                "normal" :  3439.21,
                "promo" : 3053
            },
            "motul" : {
                "normal" : 3455.21,
                "promo" : 3065
            }
        },
        "24000" : {
            "elf" : {
                "normal" :  4861.24,
                "promo" : 4475
            },
            "motul" : {
                "normal" : 4877.24,
                "promo" : 4487
            }
        },
        "36000" : {
            "elf" : {
                "normal" :  5222.31,
                "promo" : 4837
            },
            "motul" : {
                "normal" : 5238.31,
                "promo" : 4848
            }
        },
        "48000" : {
            "elf" : {
                "normal" :  4861.24,
                "promo" : 4475
            },
            "motul" : {
                "normal" : 4877.24,
                "promo" : 4487
            }
        },
        "60000" : {
            "elf" : {
                "normal" :  3439.21,
                "promo" : 3053
            },
            "motul" : {
                "normal" : 3455.21,
                "promo" : 3065
            }
        }
    }
    },
  {
      "bikeModel" : "Ninja ZX-6R",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 2133.57,
                  "promo" : 1748
              },
              "motul" : {
                  "normal" : 2149.57,
                  "promo" : 1760
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  3439.21,
                  "promo" : 3053
              },
              "motul" : {
                  "normal" : 3455.21,
                  "promo" : 3065
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  4861.24,
                  "promo" : 4475
              },
              "motul" : {
                  "normal" : 4877.24,
                  "promo" : 4487
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  5222.31,
                  "promo" : 4837
              },
              "motul" : {
                  "normal" : 5238.31,
                  "promo" : 4848
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  4861.24,
                  "promo" : 4475
              },
              "motul" : {
                  "normal" : 4877.24,
                  "promo" : 4487
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  3439.21,
                  "promo" : 3053
              },
              "motul" : {
                  "normal" : 3455.21,
                  "promo" : 3065
              }
          }
      }
  },
  {
      "bikeModel" : "Z900/Z900SE",
      "package" : "A",
      "periodic" : {
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.00,
                  "promo" : 1506
              }
          },
          "18000" : {
              "elf" : {
                  "normal" :  2509.14,
                  "promo" : 2123
              },
              "motul" : {
                  "normal" : 2525.14,
                  "promo" : 2135
              }
          },
          "30000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "42000" : {
              "elf" : {
                  "normal" :  5068.58,
                  "promo" : 4683
              },
              "motul" : {
                  "normal" : 5084.58,
                  "promo" : 4695
              }
          },
          "54000" : {
              "elf" : {
                  "normal" :  2295.14,
                  "promo" : 2123
              },
              "motul" : {
                  "normal" : 2525.14,
                  "promo" : 2135
              }
          }
          
      }
  },
  {
      "bikeModel" : "Z900RS/SE/CAFÉ",
      "package" : "A",
      "periodic" : {
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.00,
                  "promo" : 1506
              }
          },
          "18000" : {
              "elf" : {
                  "normal" :  3245.30,
                  "promo" : 2859
              },
              "motul" : {
                  "normal" : 3261.30,
                  "promo" : 2871
              }
          },
          "30000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "42000" : {
              "elf" : {
                  "normal" :  4987.26,
                  "promo" : 4601
              },
              "motul" : {
                  "normal" : 5003.26,
                  "promo" : 4613
              }
          },
          "54000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja ZX-10R/SE/RR",
      "package" : "A",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 2317.61,
                  "promo" : 1932
              },
              "motul" : {
                  "normal" : 2333.61,
                  "promo" : 1944
              }
          },
          "12000" : {
              "elf" : {
                  "normal" :  2979.61,
                  "promo" : 2594
              },
              "motul" : {
                  "normal" : 2995.61,
                  "promo" : 2606
              }
          },
          "24000" : {
              "elf" : {
                  "normal" :  7016.03,
                  "promo" : 6630
              },
              "motul" : {
                  "normal" : 7032.03,
                  "promo" : 6642
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4371.68,
                  "promo" : 3959
              },
              "motul" : {
                  "normal" : 4387.68,
                  "promo" : 3998
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  10482.83,
                  "promo" : 10097
              },
              "motul" : {
                  "normal" : 10498.83,
                  "promo" : 10109
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  2979.61,
                  "promo" : 2594
              },
              "motul" : {
                  "normal" : 2995.61,
                  "promo" : 2606
              }
          }
      }
  },
  {
      "bikeModel" : "Z1000/Z1000R",
      "package" : "A",
      "periodic" : {
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.38,
                  "promo" : 1506
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "42000" : {
              "elf" : {
                  "normal" :  5995.20,
                  "promo" : 5609
              },
              "motul" : {
                  "normal" : 6011.20,
                  "promo" : 5621
              }
          },
          "54000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja 1000",
      "package" : "A",
      "periodic" : {
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "42000" : {
              "elf" : {
                  "normal" :  5877.50,
                  "promo" : 5492
              },
              "motul" : {
                  "normal" : 5893.50,
                  "promo" : 5504
              }
          },
          "54000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          }
          
      }
  },
  {
      "bikeModel" : "VERSYS1000 15-17",
      "package" : "A",
      "periodic" : {
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "42000" : {
              "elf" : {
                  "normal" :  5913.88,
                  "promo" : 5528
              },
              "motul" : {
                  "normal" : 5925.88,
                  "promo" : 5540
              }
          },
          "54000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          }
          
      }
  },
  {
      "bikeModel" : "VERSYS1000 2019",
      "package" : "A",
      "periodic" : {
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          },
          "42000" : {
              "elf" : {
                  "normal" :  5866.80,
                  "promo" : 5481
              },
              "motul" : {
                  "normal" : 5882,
                  "promo" : 5493
              }
          },
          "54000" : {
              "elf" : {
                  "normal" :  1879.98,
                  "promo" : 1494
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1506
              }
          }
          
      }
  },
  {
      "bikeModel" : "Z250 2018 ขึ้นไป",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1359.43,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1367.43,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3598.11,
                  "promo" : 3267.04
              },
              "motul" : {
                  "normal" : 3606.11,
                  "promo" : 3273.84
              }
          },
          "18000" : {
              "elf" : {
                  "normal" :  1815.25,
                  "promo" : 1623.21
              },
              "motul" : {
                  "normal" : 1823.25,
                  "promo" : 1630.11
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4710.91,
                  "promo" : 4245.02
              },
              "motul" : {
                  "normal" : 4718.91,
                  "promo" : 4251.81
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 4485.38,
                  "promo" : 3686.07
              },
              "motul" : {
                  "normal" : 4493.38,
                  "promo" : 3692.87
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 5725.51,
                  "promo" : 5107.43
              },
              "motul" : {
                  "normal" : 5733.51,
                  "promo" : 5114.23
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1815.25,
                  "promo" : 1623.21
              },
              "motul" : {
                  "normal" : 1823.25,
                  "promo" : 1630.11
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3992.11,
                  "promo" : 3634.04
              },
              "motul" : {
                  "normal" : 4000.11,
                  "promo" : 3640.84
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja 250 2018 ขึ้นไป",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1359.43,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1367.43,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3598.11,
                  "promo" : 3267.04
              },
              "motul" : {
                  "normal" : 3606.11,
                  "promo" : 3273.84
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1815.25,
                  "promo" : 1623.21
              },
              "motul" : {
                  "normal" : 1823.25,
                  "promo" : 1630.11
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4710.91,
                  "promo" : 4245.02
              },
              "motul" : {
                  "normal" : 4718.91,
                  "promo" : 4251.82
              }
          },
          "30000" : {
              "elf" : {
                  "normal" :  1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" :  4485.38,
                  "promo" : 3686.07
              },
              "motul" : {
                  "normal" : 4493.38,
                  "promo" : 3692.87
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "48000" : {
              "elf" : {
                  "normal" :  5725.51,
                  "promo" : 5107.43
              },
              "motul" : {
                  "normal" : 5733.51,
                  "promo" : 5114.23
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1815.25,
                  "promo" : 1623.21
              },
              "motul" : {
                  "normal" : 1823.25,
                  "promo" : 1630.11
              }
          },
          "60000" : {
              "elf" : {
                  "normal" :  3992.11,
                  "promo" : 3634.04
              },
              "motul" : {
                  "normal" : 4000.11,
                  "promo" : 3640.84
              }
          }
          
      }
  },
  {
      "bikeModel" : "Z400",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1359.43,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1367.43,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3251.43,
                  "promo" : 2916.19
              },
              "motul" : {
                  "normal" : 3259.43,
                  "promo" : 2922.99
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1815.25,
                  "promo" : 1623.21
              },
              "motul" : {
                  "normal" : 1823.25,
                  "promo" : 1630.01
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4310.73,
                  "promo" : 3840.67
              },
              "motul" : {
                  "normal" : 4318.73,
                  "promo" : 3847.47
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 4192.20,
                  "promo" : 3755.98
              },
              "motul" : {
                  "normal" : 4200.20,
                  "promo" : 3762.78
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 2843.52,
                  "promo" : 2577.47
              },
              "motul" : {
                  "normal" : 2851.51,
                  "promo" : 2584.29
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3251.43,
                  "promo" : 2916.19
              },
              "motul" : {
                  "normal" : 3259.43,
                  "promo" : 2922.99
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja 400",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1359.43,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1367.43,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3251.43,
                  "promo" : 2916.19
              },
              "motul" : {
                  "normal" : 3259.43,
                  "promo" : 2922.99
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1815.25,
                  "promo" : 1623.21
              },
              "motul" : {
                  "normal" : 1823.25,
                  "promo" : 1630.01
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4310.73,
                  "promo" : 3840.67
              },
              "motul" : {
                  "normal" : 4318.73,
                  "promo" : 3847.47
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 4192.20,
                  "promo" : 3755.98
              },
              "motul" : {
                  "normal" : 4200.20,
                  "promo" : 3762.78
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 2843.52,
                  "promo" : 2577.49
              },
              "motul" : {
                  "normal" : 2851.52,
                  "promo" : 2584.29
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3251.43,
                  "promo" : 2916.19
              },
              "motul" : {
                  "normal" : 3259.43,
                  "promo" : 2922.99
              }
          }
          
      }
  },
  {
      "bikeModel" : "Z650 2017 - 2019",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3148.71,
                  "promo" : 2804.80
              },
              "motul" : {
                  "normal" : 3156.71,
                  "promo" : 2811.60
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1766.03,
                  "promo" : 1581.37
              },
              "motul" : {
                  "normal" : 1774.03,
                  "promo" : 1588.17
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4548.51,
                  "promo" : 4042.78
              },
              "motul" : {
                  "normal" : 4556.51,
                  "promo" : 4049.58
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3913.76,
                  "promo" : 3503.24
              },
              "motul" : {
                  "normal" : 3921.76,
                  "promo" : 3510.04
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 4260.20,
                  "promo" : 3861.92
              },
              "motul" : {
                  "normal" : 4268.20,
                  "promo" : 3868.72
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 3649.71,
                  "promo" : 3278.80
              },
              "motul" : {
                  "normal" : 3657.71,
                  "promo" : 3285.60
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1552.03,
                  "promo" : 1367.37
              },
              "motul" : {
                  "normal" : 1560.03,
                  "promo" : 1374.17
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 4368.51,
                  "promo" : 3889.78
              },
              "motul" : {
                  "normal" : 4376.51,
                  "promo" : 3897.78
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja 650 2017 - 2019",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3148.71,
                  "promo" : 2804.80
              },
              "motul" : {
                  "normal" : 3156.71,
                  "promo" : 2811.60
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1766.03,
                  "promo" : 1581.37
              },
              "motul" : {
                  "normal" : 1774.03,
                  "promo" : 1588.17
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4548.51,
                  "promo" : 4042.78
              },
              "motul" : {
                  "normal" : 4556.51,
                  "promo" : 4049.58
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3913.76,
                  "promo" : 3503.24
              },
              "motul" : {
                  "normal" : 3921.76,
                  "promo" : 3510.04
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 4260.20,
                  "promo" : 3861.92
              },
              "motul" : {
                  "normal" : 4268.20,
                  "promo" : 3868.72
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 3649.71,
                  "promo" : 3278.80
              },
              "motul" : {
                  "normal" : 3657.71,
                  "promo" : 3285.60
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1552.03,
                  "promo" : 1367.37
              },
              "motul" : {
                  "normal" : 1560.03,
                  "promo" : 1374.17
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 4368.51,
                  "promo" : 3889.78
              },
              "motul" : {
                  "normal" : 4376.51,
                  "promo" : 3897.78
              }
          }
          
      }
  },
  {
      "bikeModel" : "ER6N Ninja 650 12-16",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3426.91,
                  "promo" : 3041.27
              },
              "motul" : {
                  "normal" : 3434.91,
                  "promo" : 3048.07
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 3927.91,
                  "promo" : 3515.27
              },
              "motul" : {
                  "normal" : 3935.91,
                  "promo" : 3522.07
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3426.91,
                  "promo" : 3041.27
              },
              "motul" : {
                  "normal" : 3434.91,
                  "promo" : 3048.07
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 2968.71,
                  "promo" : 2732.05
              },
              "motul" : {
                  "normal" : 2976.71,
                  "promo" : 2738.85
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 3927.91,
                  "promo" : 3515.27
              },
              "motul" : {
                  "normal" : 3935.91,
                  "promo" : 3522.07
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3426.91,
                  "promo" : 3041.27
              },
              "motul" : {
                  "normal" : 3434.91,
                  "promo" : 3048.07
              }
          }
          
      }
  },
  {
      "bikeModel" : "Versys 650 /12-14",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3148.71,
                  "promo" : 2804.80
              },
              "motul" : {
                  "normal" : 3156.71,
                  "promo" : 2811.60
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4516.41,
                  "promo" : 4015.49
              },
              "motul" : {
                  "normal" : 4524.41,
                  "promo" : 4022.29
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3148.71,
                  "promo" : 2804.80
              },
              "motul" : {
                  "normal" : 3156.71,
                  "promo" : 2811.60
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 2968.71,
                  "promo" : 2732.05
              },
              "motul" : {
                  "normal" : 2976.71,
                  "promo" : 2738.85
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 4516.41,
                  "promo" : 4015.49
              },
              "motul" : {
                  "normal" : 4524.41,
                  "promo" : 4022.29
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3148.71,
                  "promo" : 2804.80
              },
              "motul" : {
                  "normal" : 3156.71,
                  "promo" : 2811.60
              }
          }
          
      }
  },
  {
      "bikeModel" : "Versys 650 /15 ขึ้นไป",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3592.76,
                  "promo" : 3182.24
              },
              "motul" : {
                  "normal" : 3600.76,
                  "promo" : 3189.04
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4093.76,
                  "promo" : 3656.24
              },
              "motul" : {
                  "normal" : 4101.76,
                  "promo" : 3663.04
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3592.76,
                  "promo" : 3182.24
              },
              "motul" : {
                  "normal" : 3600.76,
                  "promo" : 3189.04
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 2968.71,
                  "promo" : 2732.05
              },
              "motul" : {
                  "normal" : 2976.71,
                  "promo" : 2738.85
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 4093.76,
                  "promo" : 3656.24
              },
              "motul" : {
                  "normal" : 4101.76,
                  "promo" : 3663.04
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3592.76,
                  "promo" : 3182.24
              },
              "motul" : {
                  "normal" : 3600.76,
                  "promo" : 3189.04
              }
          }
          
      }
  },
  {
      "bikeModel" : "Vulcan S ABS / Café",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1361.57,
                  "promo" : 1205.48
              },
              "motul" : {
                  "normal" : 1369.57,
                  "promo" : 1212.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3592.76,
                  "promo" : 3182.24
              },
              "motul" : {
                  "normal" : 3600.76,
                  "promo" : 3189.04
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4093.76,
                  "promo" : 3656.24
              },
              "motul" : {
                  "normal" : 4101.76,
                  "promo" : 3663.04
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3592.76,
                  "promo" : 3182.24
              },
              "motul" : {
                  "normal" : 3600.76,
                  "promo" : 3189.04
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 2971.92,
                  "promo" : 2734.78
              },
              "motul" : {
                  "normal" : 2979.92,
                  "promo" : 2741.58
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 4093.76,
                  "promo" : 3656.24
              },
              "motul" : {
                  "normal" : 4101.76,
                  "promo" : 3663.04
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1107.98,
                  "promo" : 989.93
              },
              "motul" : {
                  "normal" : 1115.98,
                  "promo" : 996.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3592.76,
                  "promo" : 3182.24
              },
              "motul" : {
                  "normal" : 3600.76,
                  "promo" : 3189.04
              }
          }
          
      }
  },
  {
      "bikeModel" : "W800  (CAFÉ /STREET 2019)",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1533.58
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1543.78
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          }
          
      }
  },
  {
      "bikeModel" : "W800",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 1747.57,
                  "promo" : 1533.58
              },
              "motul" : {
                  "normal" : 1759.57,
                  "promo" : 1543.78
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1493.98,
                  "promo" : 1318.03
              },
              "motul" : {
                  "normal" : 1505.98,
                  "promo" : 1328.23
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 5986.08,
                  "promo" : 5216.56
              },
              "motul" : {
                  "normal" : 5998.08,
                  "promo" : 5226.76
              }
          }
          
      }
  },
  {
      "bikeModel" : "Z800",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 2133.57,
                  "promo" : 1861.68
              },
              "motul" : {
                  "normal" : 2149.57,
                  "promo" : 1875.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 3428.01,
                  "promo" : 3072.05
              },
              "motul" : {
                  "normal" : 3498.01,
                  "promo" : 3085.68
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 4961.23,
                  "promo" : 4393.59
              },
              "motul" : {
                  "normal" : 4977.23,
                  "promo" : 4407.19
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 3482.01,
                  "promo" : 3072.05
              },
              "motul" : {
                  "normal" : 3498.01,
                  "promo" : 3085.65
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 5327.52,
                  "promo" : 4801.24
              },
              "motul" : {
                  "normal" : 5343.52,
                  "promo" : 4814.84
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 4961.23,
                  "promo" : 4393.59
              },
              "motul" : {
                  "normal" : 4977.23,
                  "promo" : 4407.19
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 3482.01,
                  "promo" : 3072.05
              },
              "motul" : {
                  "normal" : 3498.01,
                  "promo" : 3085.65
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja H2 SX SE",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 2796.70,
                  "promo" : 2425.34
              },
              "motul" : {
                  "normal" : 2816.70,
                  "promo" : 2442.34
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 4012.70,
                  "promo" : 3587.34
              },
              "motul" : {
                  "normal" : 4032.70,
                  "promo" : 3604.34
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 2687.83,
                  "promo" : 2364.90
              },
              "motul" : {
                  "normal" : 2703.83,
                  "promo" : 2378.50
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 10089.39,
                  "promo" : 9017.35
              },
              "motul" : {
                  "normal" : 10109.39,
                  "promo" : 9034.35
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 5234.05,
                  "promo" : 4665.61
              },
              "motul" : {
                  "normal" : 5254.05,
                  "promo" : 4682.61
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 26674.39,
                  "promo" : 23114.60
              },
              "motul" : {
                  "normal" : 26694.39,
                  "promo" : 23131.60
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 2796.70,
                  "promo" : 2425.34
              },
              "motul" : {
                  "normal" : 2816.70,
                  "promo" : 2442.34
              }
          }
          
      }
  },
  {
      "bikeModel" : "Ninja H2",
      "package" : "B",
      "periodic" : {
          "1000" : {
              "elf" : {
                  "normal" : 2133.57,
                  "promo" : 1861.68
              },
              "motul" : {
                  "normal" : 2149.57,
                  "promo" : 1875.28
              }
          },
          "6000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "12000" : {
              "elf" : {
                  "normal" : 4388.53,
                  "promo" : 3850.70
              },
              "motul" : {
                  "normal" : 4408.53,
                  "promo" : 3867.70
              }
          },
          "18000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "24000" : {
              "elf" : {
                  "normal" : 10009.22,
                  "promo" : 8909.08
              },
              "motul" : {
                  "normal" : 10029.22,
                  "promo" : 8926.08
              }
          },
          "30000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "36000" : {
              "elf" : {
                  "normal" : 4388.57,
                  "promo" : 3850.70
              },
              "motul" : {
                  "normal" : 4408.53,
                  "promo" : 3867.70
              }
          },
          "42000" : {
              "elf" : {
                  "normal" : 1879.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 1895.98,
                  "promo" : 1659.73
              }
          },
          "48000" : {
              "elf" : {
                  "normal" : 13476.02,
                  "promo" : 11855.86
              },
              "motul" : {
                  "normal" : 13496.02,
                  "promo" : 11872.76
              }
          },
          "54000" : {
              "elf" : {
                  "normal" : 2093.98,
                  "promo" : 1646.13
              },
              "motul" : {
                  "normal" : 2109.98,
                  "promo" : 1659.73
              }
          },
          "60000" : {
              "elf" : {
                  "normal" : 4388.53,
                  "promo" : 3850.70
              },
              "motul" : {
                  "normal" : 4408.53,
                  "promo" : 3867.70
              }
          }
          
      }
  }
]
