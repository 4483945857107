import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, Collapse } from 'reactstrap';
import BikeModelSelector from './bikemodel';
import MilesSelector from './miles';
import { BikeData } from './data';
import Result from './Result/main';
import TicketImage from './ticket';
import { findRenderedDOMComponentWithTag } from 'react-dom/test-utils';
import { message } from 'antd';
import moment from 'moment';

class FormContent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selectedBike : "",
            selectedMiles : 0,
            currentBikeData : {},
            currentPrice : {},
            periodicArray : [],
            userInfo : {},
            isSubmitted : false,
            ticketUrl : ""
        }
        this.onBikeModelChange = this.onBikeModelChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onMilesChange = this.onMilesChange.bind(this);
    }

    componentDidMount(){
    }

    toggleCollapse(){
        this.setState({
            isPriceOpen : true
        })
    }

    onMilesChange(e){
        this.setState({
            selectedMiles : e.target.value,
            // currentPrice : this.state.currentBikeData["periodic"][e.target.value]
        })
        this.toggleCollapse()

    }

    onBikeModelChange(e){
        BikeData.map(bike => {
            if(bike.bikeModel === e.target.value){
                let pedArr = []
                Object.keys(bike.periodic).map((item,key) => {
                    pedArr.push(item)
                })
                this.setState({
                    currentBikeData: bike,
                    periodicArray : pedArr,
                    currentPrice : {}
                })
            }
        })
        this.setState({selectedBike : e.target.value})
    }

    isLoading(bool) {
        this.props.Loading(bool)
    }

    onSubmitSuccess(url) {
        this.setState({
            isSubmitted: true,
            ticketUrl: url
        },() => {
            this.isLoading(false)
            
        })
    }

    onFinish = async(params) => {
        this.props.onFinish(params)
        // const couponR = await fetch(`${process.env.REACT_APP_API_HOST_NAME}/promo/service/luckydraw-2020/getcoupon`,{
        //     method: 'POST',
        //     headers: {
        //       "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({user_data: params.data})
        // })
        // const couponJ = await couponR.json()
        // this.onSubmitSuccess(couponJ.url)
    }

    handleSubmit(e){
        e.preventDefault()
        this.isLoading(true)
        this.setState({
            userInfo : {
                fullname : e.target.fullName.value,
                phone : e.target.phone.value,
                email : e.target.email.value,
                bikemodel : this.state.selectedBike,
                miles : this.state.selectedMiles,
                price : this.state.currentPrice,
                isRepair : e.target.isRepair.checked
            }
        },() => {
            const { fullname, phone, email } = this.state.userInfo
            const arrFullname = fullname.split(" ")
            const now = moment().format()
            let params = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstname: arrFullname.filter((word,index) => index < arrFullname.length-1).join(" "),
                    lastname: arrFullname[arrFullname.length-1],
                    phone,
                    email,
                    register_info: [
                        {
                            name: "ลงทะเบียนหมุนๆลุ้นโชค พฤษจิกายน 2020",
                            json: {
                                ...this.state.userInfo
                            },
                            time: now
                        }
                    ]
                })
            }
            fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/attendees/register`,params)
            .then(response => response.json())
            .then(data => {
                if(data.status === "error" || data.message === "email is exist"){
                    message.error("เกิดข้อผิดพลาด")
                    alert("ขออภัยค่ะ คุณได้รับสิทธิไปแล้ว")
                    window.location.reload()
                    return
                }
                if(!data.id){
                    console.error(data)
                    throw "response id not found"
                }
                this.onFinish(data)
            })
            .catch((err) => {
                message.error("เกิดข้อผิดพลาดบางอย่าง")
                console.error(err)
                this.isLoading(false)
            })
        })
    } // end handleSubmit

    render(){
        return (
            <div>
                <Form style={{border: "2px solid lightgray", padding: "15px", textAlign: "left"}} onSubmit={this.handleSubmit}>
                    <FormText>กรอกข้อมูลเพื่อตรวจสอบข้อมูลและรับสิทธิประโยชน์</FormText>
                    <FormGroup>
                        <Label for="fullName">ชื่อ - นามสกุล</Label>
                        <Input type="text" name="fullName" id="fullName" placeholder="" required valid={this.state.isFullNameValid} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="Phone">เบอร์โทรศัพท์</Label>
                        <Input type="phone" name="phone" id="phone" placeholder="" required valid={this.state.isPhoneValid} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">อีเมล</Label>
                        <Input type="email" name="email" id="email" required />
                    </FormGroup>
                    <FormGroup check hidden>
                        <Label check>
                        <Input type="checkbox" name="isRepair" id="isRepair" />
                            บริการงานซ่อม
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <BikeModelSelector BikeData={BikeData} BikeModelChange={this.onBikeModelChange} />
                    </FormGroup>
                    <FormGroup>
                        {/* <MilesSelector periodicArray={this.state.periodicArray} currentBikeData={this.state.currentBikeData} MilesChange={this.onMilesChange} /> */}
                        <Label for="miles">เลขไมล์</Label>
                        <Input type="number" name="miles" id="miles" required onChange={this.onMilesChange} />
                    </FormGroup>
                    <Collapse isOpen={this.state.isPriceOpen}>
                        {/* <Result price={this.state.currentPrice} /> */}
                        <Button color="primary" type="submit" size="lg" style={{padding: "15px"}} block disabled={this.state.isSubmitted}>ยืนยันรับสิทธิ์</Button>
                    </Collapse>
                    <Collapse isOpen={this.state.isSubmitted} style={{margin: "20px"}}>
                        <TicketImage imgUrl={this.state.ticketUrl} />
                        <div style={{margin: "20px", textAlign: "center"}}>
                            <h5>แคปหน้าจอนี้หรือคลิกดาวน์โหลดภาพด้านล่าง เพื่อแสดงให้เจ้าหน้าที่ในวันที่เข้ารับบริการ</h5>
                            <span className="text-muted">และสามารถตรวจสอบได้ใน sms, email ตามเบอร์ / email ที่ลงทะเบียน</span>
                            <a href={this.state.ticketUrl} download={this.state.ticketUrl}><h5>ดาวน์โหลดคูปอง คลิก</h5></a>
                        </div>
                    </Collapse>
                </Form>
            </div>
        )
    }   
}

export default FormContent;