import React from 'react';
import { Result, Button } from 'antd';

const Confirmed = (props) => {
    return (
        <Result
            status="success"
            title="คุณลงทะเบียนสำเร็จ "
            subTitle="กรุณาแจ้งเจ้าหน้าที่เพื่อรับบัตรคิว"
            extra={[
            <a href={props.location.state.url} target="_blank" rel="noopener noreferrer" download>
                <Button type="primary" key="console">
                ดู   เอกสาร
                </Button>
            </a>
            
            ]}
        />
    )
}

export default Confirmed