import React, { useEffect, useState } from 'react'
import { Card, CardFooter, Button, ButtonGroup } from 'reactstrap'
import Styled from 'styled-components'
import { StepsLength, Introduction, Promotions, DownpaymentPayperiod, Salary, Jobduration, Credit, CreditOverdue, FinishForm } from './Steps'

const ButtonContainer = Styled.div`
  width: 100%;
  text-align: right;
  margin: 10px 0 10px 0;
`

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Question = ({ bikedata, deselectBike, setResultId }) => {
  const [ userData, setUserData ] = useState({
    downPayment: 25,
    payPeriod: 48,
    payPerMonth: undefined,
    userSalary: undefined,
    userJobDuration: undefined,
    userUsedBankLoan: undefined,
    hasOverdue: undefined
  })
  const [ promotionData, setPromotionData ] = useState()
  const [ currentPage, setPage ] = useState(0)
  const [ pageLock, setPageLock ] = useState(false)
  useEffect(() => {
    if(currentPage === 1){
      if(promotionData === undefined){
        setPageLock(true)
      } else {
        setPageLock(false)
      }
    }
    if(currentPage === 3){
      if(userData.userSalary < 1000 || !userData.userSalary){
        setPageLock(true)
      } else{
        setPageLock(false)
      }
    }
    if(currentPage === 4){
      if(!userData.userJobDuration){
        setPageLock(true)
      } else {
        setPageLock(false)
      }
    }
    if(currentPage === 5){
      if(userData.userUsedBankLoan === undefined){
        setPageLock(true)
      } else if(userData.userUsedBankLoan === true && userData.hasOverdue === undefined) {
        setPageLock(true)
      } else if(userData.userUsedBankLoan === true && userData.hasOverdue === true || userData.hasOverdue === false){
        setPageLock(false)
      } else if(userData.userUsedBankLoan === false){
        setPageLock(false)
      } else {
        console.error("Error Condition overflow")
        // throw "Error Condition overflow"
      }
    }
  },[currentPage, userData, promotionData])


  return (
    <Card className={"Question tab shadow mx-auto"}>
      {
        currentPage === 0 ?
          <Introduction /> :
        currentPage === 1 ? 
          <Promotions promotionData={promotionData} setPromotionData={setPromotionData} bikeData={bikedata} pageLock={pageLock} /> :
        currentPage === 2 ?
          <DownpaymentPayperiod userData={userData} setUserData={setUserData} promotionData={promotionData} interest={bikedata.finance.interest} price={bikedata.price} /> :
        currentPage === 3 ?
          <Salary userData={userData} setUserData={setUserData} pageLock={pageLock} /> :
        currentPage === 4 ?
          <Jobduration userData={userData} setUserData={setUserData} pageLock={pageLock} /> :
        currentPage === 5 ?
          <>
            <Credit userData={userData} setUserData={setUserData} />
            {
              userData.userUsedBankLoan == true ?
              <CreditOverdue userData={userData} setUserData={setUserData} /> :
              <></>
            }
            
          </> :
        currentPage === 6 ? 
          <FinishForm userData={userData} bikeData={bikedata} setResultId={setResultId} /> :
          <h1>Error Page not found</h1>
      }
      <CardFooter>
        <div style={{textAlign: "left"}}>
          <p>{`${capitalizeFirstLetter(bikedata.brand)} ${bikedata.name} ราคา ${new Intl.NumberFormat().format(bikedata.price)}`}</p>
        </div>
        <ButtonContainer>
          <ButtonGroup>
            { currentPage === 0 ? 
              <Button color="secondary" onClick={() => deselectBike()}>← เลือกรุ่นรถใหม่</Button> : 
              <Button color="warning" onClick={() => setPage(prevPage => {
                    setPageLock(false)
                    return prevPage-1
                  }
                )}>← กลับ
              </Button> 
            }
            { currentPage === StepsLength-1 ? 
              <></> :         
                <Button color="primary" onClick={() => setPage(prevPage => prevPage+1)} disabled={pageLock} >ต่อไป →</Button>
            }
          </ButtonGroup>
        </ButtonContainer>
      </CardFooter>
    </Card>
  )
}

export { Question }