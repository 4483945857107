import React from 'react';
import { Helmet } from 'react-helmet';
import { Result, Button } from 'antd';

const DemoRegisterConfirmed = (props) => {
    return (
        <>
            <Helmet>
                <title>แบบฟอร์มลงทะเบียนทดสอบขับขี่รถจักรยานยนต์</title>
            </Helmet>
            <Result
                status="success"
                title="คุณลงทะเบียนสำเร็จ "
                subTitle="กรุณาแจ้งเจ้าหน้าที่เพื่อทำการทดสอบ"
                extra={[
                    <a href={props.location.state.url} target="_blank" download key="downloadlink" rel="noopener noreferrer">
                        <Button type="primary" key="console">
                        ดู   เอกสาร
                        </Button>
                    </a>
                ]}
            />
        </>
    )
}

export default DemoRegisterConfirmed