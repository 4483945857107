import React, { Component } from 'react';
import { Row, Col, message, Button, Skeleton, Result, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SignaturePad from 'react-signature-pad-wrapper';

const { Text } = Typography;


const Paragraph = styled.p`
text-indent: 2rem;
text-align: justify;
text-justify: inter-word;
margin-top: 10px;
`
const Header = styled.h1`
    text-align: center;
    margin: 10px;
`
const SignatureContainer = styled.div`
    background-color: white;
    width: 100%;
    border: 5px solid black;
`

class SignAgreement extends Component{
    constructor(props){
        super(props)
        this.state = {
            signature: null,
            attendee: {
                id: null,
                data: null
            },
            isLoading: false
        }
    }
    async componentDidMount(){
        try {
            const objectId = this.props.location.query;
            if(!objectId){
                message.error("no object id")
                return
            }
            const response = await fetch(`http://${process.env.REACT_APP_API_HOST_URI}/event/contact/get?id=${objectId}`);
            const objectData = await response.json();
            if(!response.ok){
                message.error("error request")
                return
            } else {
                if(objectData.status !== "success"){
                    message.error("wrong response schema");
                    return
                }
                this.setState({
                    attendee: {
                        id: objectData.data.id,
                        data: objectData.data
                    }
                })
            }
            
        } catch (err) {
            message.error("Something went wrong 😅")
        }
    }
    clearSignature = () => {
        this.signaturePad.clear();
    }
    confirmRegister = async () => {
        if(this.signaturePad.isEmpty()) {
            message.error("กรุณาลงลายมือชื่อ")
        } else {
            try {
                this.setState({isLoading: true})
                const dataUrl = await this.signaturePad.toDataURL();
                const uploader = await fetch(`http://${process.env.REACT_APP_API_HOST_URI}/event/contact/signup`,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        vid: this.state.attendee.id,
                        sign: dataUrl
                    })
                })
                const responseJson = await uploader.json();
                if(responseJson.status === "success"){
                    message.success("success")
                    this.setState({isLoading: false}, () => {
                        this.props.history.push({
                            pathname: '/postregister/success',
                            state: {url: responseJson.url}
                        })
                    })
                } else {
                    message.error(`status: ${responseJson.status}`)
                }
                
            } catch (err) {
                message.error(err)
            }
            
        }
    }
    render(){
        const { signature } = this.state;
        const { data } = this.state.attendee;
        return (
            !this.props.location.query ? <Result status="warning" title="คุณเข้ามาที่หน้านี้อย่างไม่ถูกต้อง" extra={<Link to={"/postregister/find"}><Button type="primary" key="back">กลับไปลงทะเบียน</Button></Link>} /> :
            <Row align={"middle"} style={{marginTop: "20px", height: "100%"}}>
                <Col span={24}>
                    <Row justify={"center"} align={"middle"} style={{margin: "10px 0px", padding: "10px 0px"}}>
                        <Col xs={22} sm={20} md={16} lg={12} >
                            <Header>
                                แบบฟอร์มขอทดสอบขับขี่รถ Demo (Big Demo Big Sale 2020)
                            </Header>
                            {
                                !data ? 
                                    <Skeleton active /> 
                                : 
                                    <img src={data.driving_license} alt={"driving_license"} width="100%" />
                            }
                            {
                                !data ? 
                                    <Skeleton active /> 
                                : 
                                <>
                                    <Paragraph>
                                        ข้าพเจ้า {data.firstname} {data.lastname} มีความประสงค์ที่จะขอยืมรถจักรยานยนต์
                                        จากทางบริษัท เรียล โมโตสปอร์ต จำกัด เพื่อทดลองขับขี่ประกอบการตัดสินใจเลือกซื้อ โดยจะปฏิบัติตามข้อกําหนดของทางบริษัทฯ
                                    </Paragraph>
                                    <Paragraph>
                                        ในระหว่างการทดลองขับขี่หากมีความเสียหายใดเกิดขึ้นกับรถจักรยานยนต์ที่ยืมหรือแก่บุคคลใด
                                        เนื่องจากข้าพเจ้าไม่ปฏิบัติตามเงื่อนไข หรือประมาทเลินเล่อ ก่อให้เกิดความเสียหายอย่างใดอย่างหนึ่งขึ้นกับรถ
                                        จักรยานยนต์ที่ยืม หรือแก่ผู้อื่น ข้าพเจ้า ยินดีรับผิดชอบชดใช้ค่าเสียหายที่เกิดขึ้นทั้งหมด ตามที่บริษัทฯจะเห็นสม
                                        ควร
                                    </Paragraph>
                                </>
                            }
                            <Text code>
                                {signature}
                            </Text>
                        </Col>
                    </Row>
                    <Row justify={"center"} align={"middle"} style={{margin: "10px 0px 40px 0px", padding: "10px 0px 40px 0px"}}>
                        <Col xs={22} sm={20} md={16} lg={12}>
                            <SignatureContainer>
                                <SignaturePad ref={ref => this.signaturePad = ref} />
                            </SignatureContainer>
                            <Text type="secondary">เมื่อคุณลงลายมือชื่อนี้และกดลงทะเบียน เป็นการยอมรับข้อตกลงดังกล่าว และยืนยันว่าภาพสำเนาใบขับขี่ถูกต้อง</Text>
                        </Col>
                    </Row>
                    <Row justify={"center"} align={"middle"} style={{margin: "10px 0px 40px 0px", padding: "10px 0px 40px 0px"}}>
                        <Col xs={22} sm={20} md={16} lg={12}>
                            <Button type="secondary" onClick={this.clearSignature}>
                                ล้าง
                            </Button>
                            <Button type="primary" style={{float: "right"}} loading={this.state.isLoading} onClick={this.confirmRegister}>
                                ลงทะเบียน
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
        )
    }
}

export default SignAgreement;