import React, { useEffect, useState } from 'react'
import { Card, CardImg, CardTitle, CardSubtitle, CardText, Spinner, CardFooter, CardBody, Input, Container, Row, Col, UncontrolledTooltip, Table, Jumbotron } from 'reactstrap'
import Styled from 'styled-components'
import BikeSelectorNotFound from './BikeSelector_replace.jpg'
import RealLogo from './logo2-1.png'

const FlexContainer = Styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
margin: 0 0 5vh 0;
`
const SearchContainer = Styled.div`
position: relative;
width: 100%;
/* max-width: 600px; */
z-index: 10;
overflow: hidden;
background-color: #333;
padding: 10px;
border-radius: 6px;
`
const FilterContainer = Styled.div`
  position: fixed;
  background-color: black;
  left: 0;
  top: 50%;
  width: 50px;
  heigth: 50px;
  z-index: 2;
`

const BikeCard = ({ onSelectBike, data }) => {
  const colorScheme = ["#2DB346","#267EAD","#873CDE","#F54336","#F0B232"]
  return (
    <Card className={"BikeCard shadow"} onClick={onSelectBike} key={data.model_id} id={data.id} >
      {data.is_featured === true ?
        <div className={"ribbon ribbon-top-left"}><span>{data.featured_text || "Featured"}</span></div>
        :
        <></>
      }
      <CardImg draggable={false} top width="100%" src={data.image ? `${process.env.REACT_APP_STRAPI_HOST_NAME}${data.image.url}` : BikeSelectorNotFound} alt={data.name} />
      <CardBody>
        <CardTitle><h4>{data.name || ""}</h4></CardTitle>
        <CardTitle><h5>{data.color && data.color.name_en ? data.color.name_en : ""}</h5></CardTitle>
        <CardSubtitle>ราคา {new Intl.NumberFormat().format(data.price)} บาท</CardSubtitle>
        <CardText className="text-muted">
          <span style={{display: "block"}}>Model Year: <span style={{color: "black", fontWeight: "600"}}>{data.year}</span></span>
          <span style={{display: "blocl"}}>{data.model_name}</span>
        </CardText>
      </CardBody>
      {
        !data.promotion ? <></> : 
        <CardFooter>
          <CardText className="text-muted">
          <div className={"promo-outer"}>Promotion</div>
            <div className={"promo-outer"}>
            {
              !data.promotion.promotions ? <></> :
              data.promotion.promotions.length <= 0 ? <></> :
              data.promotion.promotions.map((pro, index) => {
                const tooltipName = `Tooltip-${data.id}-${pro.name}-${index}`
                return (
                  <>
                    <div className={"promo-inner"} id={tooltipName} style={{backgroundColor: colorScheme[index]}}>
                      <div>{pro.name}</div>
                    </div>
                    <UncontrolledTooltip target={`${tooltipName}`}>
                      {pro.discount ? <div>ส่วนลด {new Intl.NumberFormat().format(pro.discount)} บาท</div> : <></>}
                      {pro.stepup_discount ? <div>ส่วนลด Stepup {new Intl.NumberFormat().format(pro.stepup_discount)} บาท {pro.stepup_discount_condition ? `(${pro.stepup_discount_condition})` : ""}</div> : <></>}
                      {pro.insurance_firstclass ? <div>ฟรี ประกันชั้น 1</div> : <></>}
                      {pro.insurance_lossbike ? <div>ฟรี ประกันรถหาย </div> : <></>}
                      {pro.registeration_fee ? <div>ฟรี จดทะเบียน</div> : <></>}
                      {pro.act_fee ? <div>ฟรี พ.ร.บ.</div> : <></>}
                      {pro.extra_warranty_year ? <div>เพิ่ม ประกันคุณภาพเป็น {pro.extra_warranty_year} ปี</div> : <></>}
                      {
                        pro.premiums.length === 0 ? <></> : 
                        <div>
                          <div>ของสมนาคุณ: </div>
                        {pro.premiums.map(item => {
                          return (
                            <div>
                              {/* <div className="inline-block">{item.image ? <img className={"img-cropped"} src={`${process.env.REACT_APP_STRAPI_HOST_NAME}${item.image.url}`} width="100%" />: ""}</div> */}
                              <div className="inline-block">● {item.name} {item.value ? `มูลค่า ${new Intl.NumberFormat().format(item.value)} บาท` : ""}</div>
                            </div>
                          )
                        })}
                        </div>
                      }
                      {
                        !pro.special_interest ? <></> : pro.special_interest.length > 0 ? 
                          <div>
                            <div>ดอกเบี้ยพิเศษ: </div>
                            <Table bordered size="sm" style={{color: "white"}}>
                              <thead>
                                <tr>
                                  <th>ดาวน์</th>
                                  <th>ดอกเบี้ย</th>
                                  <th>งวด</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  pro.special_interest.map(spi => 
                                  <tr>
                                    <td>{spi.Down_percent != null || spi.Down_percent != undefined ? `${spi.Down_percent} %` : ""}</td>
                                    <td>{spi.interest != null || spi.interest != undefined ? `${spi.interest} %` : ""}</td>
                                    <td>{spi.max_payperiod_month ? `${spi.max_payperiod_month}` : ""}</td>
                                  </tr>)
                                }
                              </tbody>
                            </Table>
                          </div>
                        : 
                        <></>
                      }
                    </UncontrolledTooltip >
                  </>
                )
              })
            }
            </div>
          </CardText>
        </CardFooter>
      }
    </Card>
  )
}

const BikeSelector = ({ onSelectBike }) => {
  const [ BikeData, setbikeData ] = useState([])
  const [ searchKeyword, setKeyword ] = useState("")
  const [ expandFilter, setExpandFilter ] = useState(false)
  useEffect(() => {
    async function fetchData() {
      try {
        const realBikeData = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/motorcycles/getbikelist/kawasaki`)
        if(!realBikeData.ok){
          setbikeData(null)
          throw await realBikeData.text()
        }
        const realBikeJson = await realBikeData.json()
        setbikeData(realBikeJson)
      }
      catch(err){
        alert("เกิดข้อผิดพลาดบางอย่าง กรุณาดู console")
        console.error(err)
      }
    }
    fetchData()
  },[])


  const SomeBike = BikeData
    .filter(bike => { // search keyword
      const filter = bike.name.toLowerCase().replace(/\s/g, "").includes(searchKeyword.replace(/\s/g, "").toLowerCase())
      return filter
    })
    .sort((a,b) => { // sort a-z
      if(a.model_id < b.model_id){return -1}
      if(a.model_id > b.model_id){return 1}
    })
    .sort((a,b) => {
      if(a.is_featured === true && b.is_featured === false){return -1}
      if(a.is_featured === false && b.is_featured === true){return 1}
      if(a.is_featured === true && b.is_featured === true){return 0}
      if(a.is_featured === false && b.is_featured === false){return 0}
    })
    

  return (
    <>
      <SearchContainer>
        <Container>
          <Row>
            <Col md={6} sm={12} style={{marginTop: "10px"}}>
              <img src={RealLogo} width="100%" width="200px" />
            </Col>
            <Col md={6} sm={12} style={{marginTop: "10px"}}>
              <Input type="text" name="searchBar" className="searchBar" id="searchBar" placeholder="ค้นหา" onChange={(e)=>setKeyword(e.target.value)} />   
            </Col>
          </Row>
        </Container>
      </SearchContainer>
      <Jumbotron>
        <h2>โปรแกรมประเมินผลไฟแนนซ์อัตโนมัติ</h2>
        <p>กรุณาเลือกรุ่นที่คุณต้องการ</p>
      </Jumbotron>
      <FlexContainer>
        {
          BikeData === null ? <h1>Error fetch data</h1> :
          BikeData.length === 0 ? 
            <Spinner color="primary" />
          :
          SomeBike.map(item => <BikeCard data={item} onSelectBike={onSelectBike} key={item.model_id} />)
        }
      </FlexContainer>
    </>
  )
}

export { BikeSelector }