import React from 'react';

const TicketImage = ({ imgUrl }) => {
    return(
        <div id="ticketImage" style={{textAlign: "center"}}>
            <img src={imgUrl} style={{width: "80%"}} />
        </div>
    )
}


export default TicketImage