import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardFooter, CardImg, Button, CardHeader, Spinner, CardText, CardTitle, CardSubtitle, Input } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'
import { routerPath } from './config.json'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const CheckBox = styled.div`
  display: inline;
  border: 2px solid black;  
  width: 10px;
  height: 10px;
`
const CardTextLeft = styled(CardText)`
  text-align: left;
  padding: 0 30px 0 30px;
`

const GradeBox = styled.div`
  border: 3px solid red;
  width: 60px;
  height: 60px;
  text-align: center;
  display: inline-block;
  font-size: 33px;
`
const GradeContainer = styled.div`
  text-align: left;
  display: block;
  padding: 30px;

`
const GradeMessageBox = styled.div`
  display: inline;
  text-align: left;
`
const GradeName = styled.div`
  display: block;
  font-weight: 600;
  font-size: 20px;
  padding: 0 20px 0 20px;
`
function Grade({name, grade, message}){
  return (
    <GradeContainer>
      <GradeName>{name}</GradeName>
      <GradeBox>{grade}</GradeBox>
      <GradeMessageBox> {message}</GradeMessageBox>
    </GradeContainer>
  )
}

function Result({ resultId }){
  const [ resultData, setResultData ] = useState(null)
  useEffect(() => {
    async function fetchData(){
      try{
        const response = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/finance-calculators/${resultId}`)
        if(!response.ok){
          throw response.text()
        }
        const responseData = await response.json()
        setResultData(responseData)
      } catch(err){
        console.error(err)
        alert(`เกิดข้อผิดพลาดบางอย่าง`)
      }
    }
    fetchData()
  },[])
  return (
    <>
      {
        resultData === null ? <Spinner /> : 
        <>
          <Card className={"Question tab shadow mx-auto"}>
            <CardHeader>
              <h3>ผลการประเมินอย่างไม่เป็นทางการ</h3>
            </CardHeader>
            <CardBody>
              <CardImg src={`${process.env.REACT_APP_STRAPI_HOST_NAME}${!resultData.motorcycle.image ? `/uploads0/Bike_Selector_replace_14b346b562.jpg` : resultData.motorcycle.image.url}`} draggable={false} width="100%" />
              <CardTitle><h3>{`${capitalizeFirstLetter(resultData.motorcycle.brand)} ${resultData.motorcycle.name} ${resultData.motorcycle.year}`}</h3></CardTitle>
              <CardSubtitle>ราคา {new Intl.NumberFormat().format(resultData.motorcycle.price)}</CardSubtitle>
              <CardTextLeft>
                ดาวน์ {resultData.finance_info.downPayment}% | ยอดจัด {new Intl.NumberFormat().format((resultData.motorcycle.price - (resultData.motorcycle.price / 100 * resultData.finance_info.downPayment)))} บาท
              </CardTextLeft>
              <CardTextLeft>
                ผ่อน {resultData.finance_info.payPeriod} งวด | งวดละ {new Intl.NumberFormat().format(resultData.finance_info.payPerMonth)} บาท
              </CardTextLeft>
              <CardTextLeft>
                รายได้ต่อเดือน {new Intl.NumberFormat().format(resultData.finance_info.userSalary)} | อายุงาน {resultData.finance_info.userJobDuration} เดือน | <CheckBox>{resultData.finance_info.usedBankLoan === true ? `✔` : `❌`}</CheckBox> มีประวัติไฟแนนซ์ | <CheckBox>{resultData.finance_info.hasOverdue === true ? `✔` : `❌`}</CheckBox> เคยค้างชำระ
              </CardTextLeft>
              <Grade name={resultData.finance_result.salaryName} grade={resultData.finance_result.salaryGrade} message={resultData.finance_result.salaryMessage} />  
              <Grade name={resultData.finance_result.jobDurationName} grade={resultData.finance_result.jobDurationGrade} message={resultData.finance_result.jobDurationMessage} />
              <Grade name={resultData.finance_result.creditName} grade={resultData.finance_result.creditGrade} message={resultData.finance_result.creditMessage} />
              <CardText>คุณ {resultData.firstname} {resultData.lastname}</CardText>
              <CardText>เบอร์โทร: {resultData.phone}</CardText>
              <CardText className="text-muted">**หมายเหตุ ยอดดาวน์/ยอดผ่อน ไม่ได้รวมส่วนลด/ดอกเบี้ยพิเศษ จากโปรโมชั่นในแต่ละเดือน กรุณาตรวจสอบโปรโมชั่นจากเจ้าหน้าที่ๆติดต่อกลับ**</CardText>
              <CardText className="text-muted">Id: {resultData.id} Time: {moment(resultData.published_at).format('LLL')}</CardText>
            </CardBody>
            <CardFooter>
            <Link to={`${routerPath}`} ><Button color="primary">ทำใหม่ ↻</Button></Link>
            </CardFooter>
          </Card>
        </>
      }
      
      
    </>
    
 
  )
}

export { Result }