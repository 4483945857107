import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Wheel } from 'react-custom-roulette'
import { message } from 'antd'
import { ForwardOutlined } from '@ant-design/icons'
import Styled from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Spinner, Alert } from 'reactstrap';
import FormContent from './FormContent/main';
import LoadingOverlay from 'react-loading-overlay';
import coverImg from './cover-dec-2020.jpg';

const ServicePromoForm = (props) => {
  const [ isLoading, setLoading ] = useState(false)

  useEffect(() => {
  })
    
  const onLoading = (bool) => {
    setLoading(bool)
  }

  const onFinish = (params) => {
    props.history.push({
      pathname: '/promo/service/luckydraw-2020/spin',
      state: { user_id: params.id, data:params }
    })
  }

  return(
    <LoadingOverlay
      active={isLoading}
      spinner
      text='กำลังส่งข้อมูล..'
      >
      <Container style={{marginTop: "20px", marginBottom: "20px", textAlign: "center"}}>
        <Row>
          <Col>
            <img src={coverImg} alt="promoCoverImage" style={{width: "75%", margin: "20px"}} />
            <h2 style={{color: "red"}}>
              ต่อโปร!
            </h2>
            <h4>
              “หมุนๆลุ้นโชค” ลุ้นรับของรางวัลมากมาย เพียงลงทะเบียนจองคิวเช็คระยะออนไลน์
            </h4>
            <p>
              ตั้งแต่วันนี้  - 31 ธันวาคม 2563
              รีบเลย ที่ เรียล โมโตสปอร์ต ทุกสาขา
            </p>
            <p className="text-muted">**หมายเหตุ : ส่วนลด 5-10% ใช้ได้สำหรับงานประเภทเช็คระยะเท่านั้น** (ไม่รวมค่าแรง)</p>
            <h6 className="text-muted">จองคิวงานซ่อมอื่นๆ (ไม่รวมเช็คระยะ) คลิก <a href="https://lin.ee/aKDEy6W">LINE @realmotosports</a></h6>
            <p className="text-muted">***ผู้ที่ได้รับสิทธิโปรโมชั่นนี้ไปแล้วไม่สามารถรับได้ซ้ำอีก***</p>
            <FormContent Loading={onLoading} onFinish={onFinish} />
          </Col>
        </Row>
      </Container>
    </LoadingOverlay>
  )
}

class SpinPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      user_data: null,
      user_id: null,
      pizza: null,
      mustSpin: false,
      stopN: null,
      stopMessage: null,
      isSpinned: false,
      couponUrl: null
    }
  }

  componentDidMount(){
    const { user_id, data } = { ...this.props.location.state }
    if(!user_id){
      message.error("คุณเข้ามาหน้านี้อย่างไม่ถูกต้อง")
      setTimeout(() => {
        this.props.history.push({
          pathname: '/promo/service/luckydraw-2020',
          state: {}
        })

      },3000)
      return
    }
    this.setState({user_id})
    fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/wheel-spinners/getpizza?_id=${user_id}`)
    .then(res => res.json())
    .then(json => this.setState({
      pizza: json.items, 
      stopN: json.stopN, 
      stopMessage: json.get.option,
      user_data: {
        _id: json.spin_completed.attendee._id,
        isRepair: false,
        fullname: `${json.spin_completed.attendee.firstname} ${json.spin_completed.attendee.lastname}`,
        bikemodel: json.spin_completed.attendee.register_info[0].json.bikemodel,
        miles: json.spin_completed.attendee.register_info[0].json.miles,
        gotDiscount: json.spin_completed.giveaway_get.name,
        giveawayId: json.get._id,
        spinId: json.spin_completed._id
      }
    }))
    .catch(err => {throw err})
  }

  startSpin = () => {
    this.setState({mustSpin: true, isSpinned: true})
  }

  onSpinFinish = async () => {
    alert(`คุณได้รับ ${this.state.stopMessage}`)
    try{
      const couponR = await fetch(`${process.env.REACT_APP_API_HOST_NAME}/promo/service/luckydraw-2020/getcoupon`,{
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({user_data: this.state.user_data})
      })
      const couponJ = await couponR.json()
      this.setState({
        couponUrl: couponJ.url
      }, async () => {
        try {
        const saveCoupon = await fetch(`${process.env.REACT_APP_STRAPI_HOST_NAME}/coupons`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "wheel_spinner_completed": this.state.user_data.spinId,
            "owner": this.state.user_data._id,
            "url": this.state.couponUrl
          })
        })
        if(!saveCoupon.ok){
          message.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล")
          return
        }
        const saveCouponJ = await saveCoupon.json()
        if(saveCouponJ._id){
          message.success("บันทึกข้อมูลลงในระบบแล้ว")
          return
        }
      } catch(err){
        message.error(err)
      }
      })
    } catch(err) {
      message.error("เกิดข้อผิดพลาดบางอย่าง")
      console.error(err)
    }

  }

  render(){
    const { mustSpin, stopN, pizza, user_id, couponUrl } = this.state
    const SpinButton = Styled.button`
      background-color: #df3428;
      color: white;
      border: 5px solid black;
      border-radius: 10px;
      width: 120px;
      height: 60px;
      margin: 50px;
      ${props => props.disabled && `
        background-color: gray;
        color: white;s
      `}
    `
    return (
      <Container>
        <Row>
            <Col></Col>
            <Col style={{justifyContent: "center", justifyItems: "center", textAlign: "center"}}>
              <div>
              {
                // fakePizza === null ?
                pizza === null && couponUrl === null ? 
                  <Spinner style={{ width: '3rem', height: '3rem' }} /> 
                : pizza && couponUrl === null ?
                  <>
                    <Wheel
                      mustStartSpinning={mustSpin}
                      prizeNumber={stopN}
                      data={pizza}
                      backgroundColors={['#e5514f', '#ff9c2e','#3fb680','#50a5e5','#c63f77']}
                      textColors={['#ffffff']}
                      outerBorderColor={'white'}
                      outerBorderWidth={8}
                      innerBorderColor={'white'}
                      radiusLineColor={'white'}
                      onStopSpinning={() => this.onSpinFinish()}
                    />
                    <SpinButton disabled={this.state.isSpinned} onClick={() => this.startSpin()}>หมุน</SpinButton>
                  </>
                : 
                  <>
                    <img src={couponUrl} width={"600px"} />
                    <p className="text-muted">กรุณาแคปหน้าจอนี้หรือเซฟภาพคูปองลงเครื่องของท่าน</p>
                    <a href={couponUrl}>{couponUrl}</a>
                  </>
              }
              </div>
            </Col>
            <Col></Col>
        </Row>
      </Container>
    )
  }
}

export { ServicePromoForm, SpinPage }