import React, { useState } from 'react';
import { Input, Row, Col, message, Table, Empty, Button } from 'antd';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
const { Search } = Input;

const FindRegister = () => {
    const [ isLoading, changeLoading ] = useState(false);
    const [ dataSource, changeDataSource ] = useState({status: null, data: null});
    const onSearch = async (value) => {
        changeLoading(true)
        try {
            const response = await fetch(`http://${process.env.REACT_APP_API_HOST_URI}/event/contact/search?query=${value}`);
            const data = await response.json()
            if(!response.ok){
                message.error(`Error something response : ${JSON.stringify(data)}`);
                changeLoading(false)
                return
            }
            if (response.ok && data.status === "error"){
                message.error(`Error reason : ${data.reason}`)
                changeLoading(false)
                return
            }
            if (response.ok && data.status === "success"){
                const fetchedData = data.data.map((item, index) => ({
                    key: index,
                    id: item.id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    phone: item.phone,
                    email: item.email,
                    confirm: item.id
                }))
                changeDataSource({
                    status: "loaded",
                    data: fetchedData
                })
                changeLoading(false)
                return
            }
        } catch(err) {
            console.error(err)
            changeLoading(false)
            message.error("Network Error ! please try again another times / another devices")
        }
    }
    const columns = [
        {
            title: "ชื่อจริง",
            dataIndex: "firstname",
            key: "firstname",
        },
        {
            title: "นามสกุล",
            dataIndex: "lastname",
            key: "lastname"
        },
        {
            title: "เบอร์",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "อีเมล",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "ลงทะเบียน",
            dataIndex: "confirm",
            key: "confirm",
            align: "center",
            render: id => <Link to={{pathname: "/postregister/sign", query: id}}><Button type={"primary"}>ลงทะเบียน</Button></Link>
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: id => <a href={`https://app.hubspot.com/contacts/4759029/contact/${id}/`} target="_blank" rel="noopener noreferrer">{id}</a>
        },
    ]
    return (
        <Row align={"middle"} style={{marginTop: "20px", height: "calc(100vh - 64px - 20px)"}}>
            <Col span={24}>
                <Row justify={"center"} align={"middle"} style={{marginBottom: "30px", paddingBottom: "30px"}}>
                    <Col xs={23} sm={20} md={16} lg={12} >
                        <Search
                            placeholder="ชื่อ / เบอร์โทร / อีเมล"
                            enterButton="Search"
                            size="large"
                            onSearch={value => onSearch(value)}
                            loading={isLoading}
                            prefix={<SearchOutlined />}
                        />
                    </Col>
                </Row>
                <Row justify={"center"} align={"middle"} style={{marginTop: "30px", paddingTop: "30px"}}>
                    <Col span={23} >
                        {
                            dataSource.status === null 
                            ? 
                                <Empty description={false}  /> 
                            :
                                <Table 
                                    columns={columns} 
                                    dataSource={dataSource.data} 
                                    scroll={{
                                        x: "100%"
                                    }}
                                    pagination={false}
                                    bordered={true}
                                />
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
        
    )
}

export default FindRegister;